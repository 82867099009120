import React, { FC } from "react";

import { useProfileData } from "@hooks/useProfileData";

import TextFieldForm from "@components/Form/TextFieldForm";

import { sharedProps } from "./RootPage";

interface SectionTitleProps {
  type: "schedulers" | "members" | "packages";
  placeholder: string;
  isMemberPage: boolean;
}

const SectionTitle: FC<SectionTitleProps> = ({
  type,
  placeholder,
  isMemberPage,
}) => {
  const { data, updateData } = useProfileData(isMemberPage);
  const sectionTitles = data?.sectionTitles;

  return (
    <TextFieldForm
      {...sharedProps}
      name={`${type}SectionTitle`}
      placeholder={placeholder}
      onInputChange={(e) => {
        updateData({
          sectionTitles: { ...sectionTitles, [type]: e.target.value },
        });
      }}
      otherInputProps={{ value: sectionTitles?.[type] }}
    />
  );
};

export default SectionTitle;
