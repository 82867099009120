import { useEffect } from "react";
import Router from "next/router";

type UseWarnIfUnsavedChangesType = (args: {
  isDirty?: boolean;
  isSubmitting?: boolean;
  message?: string; // to customize the alerts when needed
}) => void;

// https://github.com/vercel/next.js/issues/2476#issuecomment-563190607
const useWarnIfUnsavedChanges: UseWarnIfUnsavedChangesType = ({
  isDirty = false,
  isSubmitting = false,
  message,
}) => {
  useEffect(() => {
    const unsavedChanges: boolean = isDirty || isSubmitting;
    let warnMessage: string;
    if (message) {
      warnMessage = message;
    } else if (isDirty) {
      warnMessage = "If you leave your changes will be lost";
    } else if (isSubmitting) {
      warnMessage = "Please wait until your changes are saved";
    }
    const routeChangeStart = (url) => {
      if (Router.asPath !== url && unsavedChanges && !confirm(warnMessage)) {
        Router.events.emit("routeChangeError");
        Router.replace(Router, Router.asPath);
        throw "Abort route change. Please ignore this error.";
      }
    };

    const beforeunload = (e) => {
      if (unsavedChanges) {
        e.preventDefault();
        e.returnValue = warnMessage;
        return warnMessage;
      }
      warnMessage;
    };

    window.addEventListener("beforeunload", beforeunload);
    Router.events.on("routeChangeStart", routeChangeStart);

    return () => {
      window.removeEventListener("beforeunload", beforeunload);
      Router.events.off("routeChangeStart", routeChangeStart);
    };
  }, [isDirty, isSubmitting]);
};

export default useWarnIfUnsavedChanges;
