import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const LinkedInIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21 14.0358V21H17.142V14.5022C17.142 12.8699 16.584 11.7559 15.1875 11.7559C14.1215 11.7559 13.4871 12.5067 13.208 13.2329C13.1063 13.4925 13.0801 13.8539 13.0801 14.2173V21H9.22078C9.22078 21 9.27276 9.99487 9.22078 8.85466H13.0797V10.5762C13.0719 10.5891 13.0617 10.603 13.0543 10.6155H13.0797V10.5762C13.5924 9.74984 14.508 8.56933 16.5574 8.56933C19.0965 8.56929 21 10.3052 21 14.0358ZM5.18381 3C3.86356 3 3 3.90626 3 5.09802C3 6.26381 3.83857 7.19757 5.13259 7.19757H5.15837C6.50419 7.19757 7.34117 6.26398 7.34117 5.09802C7.31581 3.90626 6.50419 3 5.18381 3ZM3.22921 21H7.08702V8.85466H3.22921V21Z" />
    </svg>
  );
};

export default LinkedInIcon;
