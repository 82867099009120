import React, { useEffect, useState } from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";

import { Dialog } from "@components/Dialog";

interface ColorFieldProps {
  label: string;
  color: string;
  onChange: any;
}

const ColorField: React.FC<ColorFieldProps> = ({
  label,
  color: current,
  onChange,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [color, setColor] = useState(current);

  useEffect(() => {
    setColor(current);
  }, [isOpen]);

  return (
    <div className="">
      <label className="block text-xs leading-5 text-grey-500 w-full">
        {label}
      </label>
      <div className="flex items-end">
        <HexColorInput
          className="w-full h-[32px] mr-2 mt-1 py-2 px-3 border border-grey-900 rounded-md focus:bg-white focus:ring-0 focus:border-action-700 transition duration-150 ease-in-out"
          color={current}
          onChange={onChange}
        />
        <button
          style={{ backgroundColor: current }}
          className="w-[32px] h-[32px] border border-grey-900 rounded-md  hover:border-grey-950 focus:bg-white focus:border-action-700 transition duration-150 ease-in-out"
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>
      {isOpen && (
        <Dialog
          borderRadius="medium"
          width="[100px]"
          show={isOpen}
          setShow={setIsOpen}
          className="absolute mt-[-115px] ml-[39px]"
          noPadding
        >
          <React.Fragment>
            <HexColorPicker color={color} onChange={onChange} />
          </React.Fragment>
        </Dialog>
      )}
    </div>
  );
};

export default ColorField;
