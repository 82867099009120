import classNames from "classnames";

import { useAuth } from "@contexts/auth";
import { usePublicProfile } from "@contexts/publicProfile";

import TextAreaForm from "@components/Form/TextAreaForm";
import TextFieldForm from "@components/Form/TextFieldForm";
import InfoIcon from "@components/Icons/InfoIcon";
import FileInput, { FileData } from "@components/PublicProfile/FileInput";
import PublicProfileSidebarSection from "@components/PublicProfile/PublicProfileSidebarSection";
import { RoundFileInput } from "@components/PublicProfile/RoundFileInput";
import { Tooltip, TooltipVariant } from "@components/Tooltips/Tooltip";

import { sharedProps } from "./RootPage";

export const renderTooltipHeader = (title: string, tooltipText: string) => (
  <div className="text-grey-500 flex items-center gap-1 mb-2">
    <h2 className="text-sm">{title}</h2>
    <Tooltip
      variant={TooltipVariant.black}
      trigger={<InfoIcon className="w-5 h-5" />}
    >
      {tooltipText}
    </Tooltip>
  </div>
);

const PublicPresenceSection = () => {
  const { oid } = useAuth();
  const { data, updateCompanyData } = usePublicProfile();
  const isCompany = !!data?.featureNames?.companyProfile;

  const { companyDetails } = data ?? {};
  const { metaDescription, metaTitle, openGraphImage, favicon } =
    companyDetails ?? {};

  return (
    <PublicProfileSidebarSection title="Public presence">
      {isCompany && (
        <div>
          {renderTooltipHeader(
            "Image preview",
            "The image preview of your brand used for all public-facing links"
          )}
          <FileInput
            data={openGraphImage ? ({ url: openGraphImage } as FileData) : null}
            uploadPath={`/users/${oid}/openGraphImage/`}
            onChange={(value) =>
              updateCompanyData({ openGraphImage: value?.url || null })
            }
            wrapperClassName="h-[170px]"
            imageClassName="object-fit"
            fullsizePreview
          />
        </div>
      )}
      <div className="flex gap-4">
        <div>
          <h2 className={classNames("text-sm text-grey-500", "mb-2")}>
            Favicon
          </h2>
          <RoundFileInput
            aid={oid}
            url={favicon ?? undefined}
            onChange={(url) => updateCompanyData({ favicon: url })}
            type="favicons"
          />
        </div>
        <div>
          {renderTooltipHeader(
            "Meta title",
            "The title of all links shared for public-facing links"
          )}
          <TextFieldForm
            {...sharedProps}
            name="metaTitle"
            placeholder="Enter title"
            onInputChange={(e) => {
              updateCompanyData({ metaTitle: e.target.value });
            }}
            otherInputProps={{ value: metaTitle }}
          />
        </div>
      </div>
      {renderTooltipHeader(
        "Meta description",
        "The description of all links shared for public-facing links"
      )}
      <TextAreaForm
        name="metaDescription"
        containerClassName="!mb-0 -mt-1"
        onChange={(e) => {
          updateCompanyData({ metaDescription: e.target.value });
        }}
        value={metaDescription}
        maxRows={10}
      />
    </PublicProfileSidebarSection>
  );
};

export default PublicPresenceSection;
