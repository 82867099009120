import { FC, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";
import axios from "axios";

import { OrganizationType } from "@lib/data/schemas/organization";

import { Button } from "@components/Button";
import TextField from "@components/Client/TextField";
import { ErrorMessage } from "@components/ErrorMessage";
import CheckIcon from "@components/Icons/CheckIcon";

interface FormValues {
  name: string;
  email: string;
  message: string;
}

interface PublicPageMessageProps {
  user: Partial<OrganizationType> & { orgId?: string };
}

const PublicPageMessage: FC<PublicPageMessageProps> = ({ user }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    mode: "onTouched",
    defaultValues: { name: "", email: "", message: "" },
  });
  const [loading, setLoading] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const errorMessage = useState()[0];

  const memberOrgId = user?.orgId;

  const onSubmit: SubmitHandler<FormValues> = async (data, e) => {
    e?.preventDefault();

    setLoading(true);

    if (!data) return;

    const { name, email, message } = data;

    try {
      const endpoint = `/api/v1/organizations/${
        memberOrgId ? memberOrgId : user.id
      }/message`;
      const response = await axios.post(endpoint, {
        visitorName: name,
        email,
        message,
        ...(memberOrgId && { memberId: user.id }),
      });

      if (response) {
        setMessageSent(true);
        setValue("name", "");
        setValue("email", "");
        setValue("message", "");
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  return (
    <div className="w-full">
      <TextField
        id="name"
        type="text"
        placeholder="Name"
        required={true}
        register={register}
        errors={errors}
        errorMessage="Name is required"
      />

      <TextField
        id="email"
        type="email"
        placeholder="Email"
        required={true}
        register={register}
        errors={errors}
        errorMessage="Email is required"
        isEmail
        isEmailErrorMessage="The email needs a valid format"
      />

      <p className="font-medium text-lg mt-8 text-foreground/75">Message</p>
      <div className="my-2">
        <TextareaAutosize
          {...register("message", { required: true })}
          className="form-input block w-full my-2 py-2.5 px-4 rounded-lg overflow-hidden text-base bg-background border border-foreground/20 hover:border-foreground/7 hover:bg-foreground/7 focus:outline-none focus:shadow-none focus:bg-background focus:border-accent focus:ring-0 transition duration-150 ease-in-out placeholder-foreground/50 hover:bg-grey-950"
          minRows={2}
          placeholder="Write a message..."
        />
      </div>

      {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}

      <div className="mt-8 flex flex-col md:flex-row items-start sm:items-center justify-between w-full">
        <div>
          <Button
            variant="primary-theme"
            onClick={handleSubmit(onSubmit)}
            isLoading={loading}
          >
            Send
          </Button>
        </div>
        {messageSent ? (
          <div className="flex justify-center w-full text-lg items-center mt-8 md:mt-0">
            <CheckIcon
              className="text-green-600 m-0 md:ml-3"
              width={28}
              height={28}
            />
            <div className="pl-3 font-medium">Your message was sent!</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PublicPageMessage;
