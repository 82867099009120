import { FC } from "react";

import { PublicProfileUserData } from "@contexts/publicProfile/types";
import { displayNameFromContact } from "@lib/contacts";
import { profileOGImage } from "@lib/og";
import { SanitizedUserType } from "@lib/shared-types";

import ClientFooterLayout from "@components/Client/ClientFooterLayout";
import MetaHead from "@components/MetaHead";
import PublicPageMessage from "@components/PublicPageMessage";

interface PublicPageLayoutProps {
  user: PublicProfileUserData;
}

export const PublicPageFooter: FC<PublicPageLayoutProps> = ({ user }) => {
  return (
    <div>
      <div className="grid sm:grid-cols-2 w-full grid-cols-1 sm:justify-items-stretch pt-10">
        <h3 className="grid-span-1 text-2xl font-medium flex justify-center sm:justify-start text-foreground">
          Have a question?
        </h3>
        <div className="sm:grid-start-2 pt-8 sm:pt-16">
          <PublicPageMessage user={user} />
        </div>
      </div>

      <div className="pt-20 md:pr-5">
        <ClientFooterLayout
          coach={user as unknown as SanitizedUserType}
          landingPage
        />
      </div>
    </div>
  );
};

export const PublicPageMetaHeader: FC<PublicPageLayoutProps> = ({ user }) => {
  const {
    avatarURL,
    slug,
    website,
    title,
    bio,
    companyDetails,
    featureNames,
    companyName,
  } = user;

  const isCompany = !!featureNames?.companyProfile;

  const { metaTitle, metaDescription, openGraphImage } = companyDetails ?? {};
  const image =
    isCompany && openGraphImage
      ? openGraphImage
      : profileOGImage(avatarURL || undefined);

  const showCompanyMetaTitle = isCompany && metaTitle;

  const orgName =
    isCompany && companyName
      ? companyName
      : displayNameFromContact(user, isCompany);
  const personSchema = JSON.stringify({
    "@context": "https://schema.org/",
    "@type": "Person",
    name: orgName,
    url: `https://app.practice.do/me/${slug}`,
    image,
    sameAs: website,
    jobTitle: showCompanyMetaTitle ? metaTitle : title,
  });

  return (
    <MetaHead
      title={(showCompanyMetaTitle ? metaTitle : orgName) ?? ""}
      subTitle={showCompanyMetaTitle ? "" : title}
      titleContainsPractice={false}
      description={
        (isCompany && metaDescription) ||
        bio ||
        `View ${orgName} on Practice.do. Find more info, read reviews, and book a session with ${orgName}!`
      }
      image={image}
    >
      {/* eslint-disable-next-line */}
      {/* @ts-ignore */}
      <script
        type="application/id+json"
        dangerouslySetInnerHTML={{ __html: personSchema }}
      ></script>
    </MetaHead>
  );
};
