import { FC } from "react";
import { DragDropContextProps } from "react-beautiful-dnd";
import { clone, uniqueId } from "lodash";
import { useCollection } from "swr-firebase";

import { useAuth } from "@contexts/auth";
import { PageResourceType } from "@contexts/publicProfile/types";
import useCustomFields from "@hooks/use-custom-fields";
import { useProfileData } from "@hooks/useProfileData";
import { displayNameFromContact } from "@lib/contacts";
import { ClientType } from "@lib/data/schemas/client";
import { TestimonialType } from "@lib/data/schemas/user";
import { CustomFieldType } from "@lib/shared-types";

import { AddButton } from "@components/Buttons";
import ClientAvatar from "@components/Client/ClientAvatar";
import FormDragAndDrop from "@components/FormDragAndDrop";
import DraftIcon from "@components/Icons/DraftIcon";
import Listbox, { ListboxOption } from "@components/Listbox";
import {
  PublicProfileSidebarPage,
  PublicProfileSidebarPageProps,
} from "@components/PublicProfile/PublicProfileSidebar/Pages";
import PublicProfileSidebarItem from "@components/PublicProfile/PublicProfileSidebarItem";
import PublicProfileSidebarSection from "@components/PublicProfile/PublicProfileSidebarSection";

type ExtendedTestimonial = TestimonialType & {
  icon: JSX.Element;
  index: number;
};

const TestimonialsPage: FC<PublicProfileSidebarPageProps> = ({
  setSelectedPage,
  isMemberPage = false,
}) => {
  const { oid } = useAuth();
  const { data, updateData, setPageResource } = useProfileData(isMemberPage);
  const testimonials = data?.testimonials;

  const temp = clone(testimonials) || [];

  const { customFields } = useCustomFields({ coachId: oid });
  const testimonialField = customFields?.find(
    (f) => f.type === CustomFieldType.Testimonial
  );

  const { data: clients } = useCollection<ClientType>(
    oid && testimonialField ? `/users/${oid}/clients` : null,
    {
      orderBy: "customFields",
      listen: true,
    }
  );

  const fieldFilter = (f: { field: string }) =>
    f.field === testimonialField!.field;

  const clientsWithTestimonials =
    clients?.filter((d) => d.customFields?.find(fieldFilter)) || [];

  const handleClick = (testimonial: ExtendedTestimonial) => {
    setPageResource(testimonial as unknown as PageResourceType);
    setSelectedPage(PublicProfileSidebarPage.editTestimonial);
  };

  const handleOnChange = (data: ClientType | "new") => {
    if (data === "new") {
      temp?.push({ name: "", text: "" });
      const last = temp?.length && temp?.length - 1;
      const resource = {
        ...temp?.[last],
        icon: <ClientAvatar client={{}} />,
        index: last,
      };
      updateData({ testimonials: temp });
      resource && handleClick(resource);
    } else {
      temp?.push({
        clientId: data.id,
        name: displayNameFromContact(data) || "",
        text: data.customFields?.find(fieldFilter)?.value,
      });
      updateData({ testimonials: temp });
    }
  };

  const onDragEnd: DragDropContextProps["onDragEnd"] = (result) => {
    if (!result.destination) return; // dropped outside the list
    const removed = temp?.splice(result.source.index, 1);
    temp?.splice(result.destination.index, 0, removed![0]);
    updateData({ testimonials: temp });
  };

  return (
    <PublicProfileSidebarSection title="Testimonials">
      <div>
        <div>
          <FormDragAndDrop
            onDragEnd={onDragEnd}
            fields={testimonials?.map((t) => ({ ...t, id: uniqueId() })) || []}
            itemClassname="w-full mb-2"
          >
            {(field, index) => {
              const client =
                field?.clientId &&
                clientsWithTestimonials.find((c) => c.id === field?.clientId);
              const icon = (
                <ClientAvatar
                  client={client ? client : { firstName: field?.name }}
                />
              );
              return (
                <div>
                  <PublicProfileSidebarItem
                    key={`testimonial-${index}`}
                    icon={icon}
                    title={field?.name || ""}
                    onClick={() => handleClick({ ...field, icon, index })}
                  />
                </div>
              );
            }}
          </FormDragAndDrop>
        </div>
        <Listbox
          onChange={handleOnChange}
          clearable
          customAddButton={true}
          placeholder={(() => (
            <AddButton className="py-5 hover:bg-grey-800/30">
              Add a testimonial
            </AddButton>
          ))()}
        >
          <ListboxOption
            className="bg-white hover:bg-white"
            value="new"
            key="new"
          >
            <div className="bg-grey-950 -my-2 text-base h-14 -ml-3 -mr-1 rounded-xl text-grey-500 flex items-center pl-3 cursor-pointer">
              <DraftIcon />
              <div className="ml-4">Write a testimonial...</div>
            </div>
          </ListboxOption>
          {clientsWithTestimonials
            .filter(
              (t) => !testimonials?.map((f) => f?.clientId).includes(t.id)
            )
            .map((client, index) => (
              <ListboxOption key={index} value={client}>
                <div className="flex items-center">
                  <div>
                    <ClientAvatar client={client} />
                  </div>
                  <div className="ml-4">{displayNameFromContact(client)}</div>
                </div>
              </ListboxOption>
            ))}
        </Listbox>
      </div>
    </PublicProfileSidebarSection>
  );
};

export default TestimonialsPage;
