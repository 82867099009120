import { usePublicProfile } from "@contexts/publicProfile";
import { useMemberPublicProfile } from "@contexts/publicProfile/memberPublicProfile";
import { PageResourceType } from "@contexts/publicProfile/types";
import { AccountType } from "@lib/data/schemas/account";

import { getMemberResourceData } from "@components/PublicProfile/PublicProfileSidebar/utils";

export const useProfileData = (isMember: boolean) => {
  const {
    data: orgData,
    updateData: updateOrgData,
    hasChangedSlug: orgChangedSlug,
    hasUnsavedChanges: orgUnsavedChanges,
    publishChanges: publicOrgChanges,
    pageResource,
    setPageResource,
  } = usePublicProfile();

  const {
    data: memberData,
    updateData: updateMemberData,
    hasChangedSlug: memberChangedSlug,
    hasUnsavedChanges: memberUnsavedChanges,
    publishChanges: publicMemberChanges,
  } = useMemberPublicProfile();

  const isNestedMember = pageResource?.isMemberResource;

  const data = isMember
    ? memberData
    : isNestedMember
    ? getMemberResourceData(pageResource as PageResourceType, orgData!)
    : orgData;

  const nestedMemberUpdate = (payload: Partial<AccountType>) => {
    const updatedMembers = orgData?.members?.map((member) => {
      if (member.id === pageResource?.id) {
        return { ...member, ...payload };
      }
      return member;
    });
    const member = updatedMembers?.find((m) => m.id === pageResource?.id);
    setPageResource({ ...member, isMemberResource: true } as PageResourceType);
    updateOrgData({ members: updatedMembers });
  };

  const updateData = isMember
    ? updateMemberData
    : isNestedMember
    ? nestedMemberUpdate
    : updateOrgData;

  const hasChangedSlug = isMember ? memberChangedSlug : orgChangedSlug;
  const hasUnsavedChanges = isMember ? memberUnsavedChanges : orgUnsavedChanges;
  const publishChanges = isMember ? publicMemberChanges : publicOrgChanges;

  return {
    data,
    updateData,
    hasChangedSlug,
    hasUnsavedChanges,
    publishChanges,
    setPageResource,
    pageResource,
    isNestedMember,
  };
};
