import { FC } from "react";

import { usePublicProfile } from "@contexts/publicProfile";

import { PublicProfileSidebarPageProps } from "@components/PublicProfile/PublicProfileSidebar/Pages";

import AppearanceSection from "./AppearanceSection";
import LayoutSection from "./LayoutSection";
import PublicPresenceSection from "./PublicPresenceSection";

const AppearanceAndLayoutPage: FC<PublicProfileSidebarPageProps> = () => {
  const { data } = usePublicProfile();

  if (!data) return null;

  // change this later to white label
  const isCompany = data.featureNames?.companyProfile;

  return (
    <>
      <AppearanceSection />
      <LayoutSection />
      {isCompany && <PublicPresenceSection />}
    </>
  );
};

export default AppearanceAndLayoutPage;
