import { FC, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";
import Image from "next/image";
import { v4 as uuid } from "uuid";

import useSnackbar from "@hooks/use-snackbar";
import { compatStorage as storage } from "@lib/firebase-config";

import IconAction from "@components/IconAction";
import PictureIcon from "@components/Icons/PictureIcon";
import TrashIcon from "@components/Icons/TrashIcon";
import LoadingSpinner from "@components/LoadingSpinner";

export interface FileData {
  url: string;
  fileName: string;
  size: number;
  contentType: string;
  fullPath?: string;
  bucket?: string;
  extension?: string;
}

interface FileInputProps {
  uploadPath: string;
  data: FileData | null;
  onChange: (data: FileData | null) => void;
  fullsizePreview?: boolean;
  wrapperClassName?: string;
  imageClassName?: string;
}

const FileInput: FC<FileInputProps> = ({
  uploadPath,
  data,
  onChange,
  fullsizePreview,
  wrapperClassName,
  imageClassName,
}) => {
  const { showWarning } = useSnackbar();
  const [isUploading, setIsUploading] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      if (!file) {
        showWarning("This file type is not supported");
        return;
      }

      setIsUploading(true);

      const itemId = uuid();
      const fileName = file.name;
      const extension = fileName.split(".").pop();
      const metadata = { contentDisposition: `filename="${fileName}"` };

      const uploadTask = storage
        .ref()
        .child(`${uploadPath}${itemId}`)
        .put(file, metadata);

      uploadTask.on(
        "state_changed",
        undefined,
        (error) => {
          if (error.code === "storage/canceled") return;

          setIsUploading(false);
          showWarning("Could not upload file", error.message);
        },
        async () => {
          const url = await uploadTask.snapshot.ref.getDownloadURL();
          const { contentType, size, fullPath, bucket } =
            await uploadTask.snapshot.ref.getMetadata();

          onChange({
            fileName,
            size,
            contentType: contentType || "",
            url,
            extension,
            fullPath,
            bucket,
          });

          setIsUploading(false);
        }
      );
    },
    [onChange, showWarning, uploadPath]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    maxFiles: 1,
    onDrop,
  });

  return (
    <div
      className={classNames(
        "relative h-[100px] flex items-center justify-center gap-2 border border-dashed border-grey-800 rounded-lg overflow-hidden",
        wrapperClassName,
        data ? "cursor-auto" : "hover:bg-white",
        isDragActive && "bg-white"
      )}
      {...getRootProps()}
    >
      {isUploading ? (
        <>
          <LoadingSpinner variant="transparent" />
        </>
      ) : data && !isDragActive ? (
        <>
          <Image
            className={classNames(
              "max-w-full max-h-full object-contain",
              !fullsizePreview && "p-3",
              imageClassName
            )}
            src={data.url}
            alt="Company logo preview"
            fill
          />
          <IconAction
            className="absolute right-2 bottom-2 bg-white rounded-lg shadow-sm"
            type="dropdown"
            showOnHover={false}
            size="small"
            dropdownItems={[
              {
                type: "button",
                title: "Remove",
                Icon: TrashIcon,
                onClick: () => onChange(null),
                variant: "delete",
              },
            ]}
          />
        </>
      ) : (
        <>
          <input {...getInputProps()} accept="image/*" />
          <div className="flex flex-col items-center gap-2">
            <PictureIcon className="text-grey-500" />
            <div className="flex flex-col items-center text-sm">
              {isDragActive ? (
                <p>Drop to upload</p>
              ) : (
                <p>
                  Drop or{" "}
                  <span className="text-action-500 underline">upload file</span>
                </p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FileInput;
