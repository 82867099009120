import { FC } from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import classNames from "classnames";

import { TodoColorType } from "@lib/shared-types";

import CheckIcon from "@components/Icons/CheckIcon";

interface FilledCheckboxProps {
  className?: string;
  name?: string;
  value?: boolean;
  onChange?: (checked: boolean | "indeterminate") => void;
  defaultChecked?: boolean;
  color?: TodoColorType;
  disabled?: boolean;
  required?: boolean;
  variant?: "round" | "default";
}

const FilledCheckbox: FC<FilledCheckboxProps> = ({
  className,
  name,
  value,
  onChange,
  defaultChecked = false,
  color = "grey",
  disabled = false,
  required = false,
  variant = "default",
}) => {
  const uncheckedStyleByColor = {
    grey: "border-grey-800",
    messaging: "dark:border-white border-action-300",
    action: "border-action-300",
  };
  const checkedStyleByColor = {
    grey: "bg-grey-500",
    action: "bg-action-300",
    messaging: "dark:bg-grey-150 bg-action-300",
  };
  const variantStyle = {
    round:
      "w-5 h-5 rounded-full bg-transperent cursor-pointer focus:outline-none flex items-center justify-center border-2",
    default:
      "w-5 h-5 rounded-sm bg-transperent cursor-pointer focus:outline-none flex items-center justify-center border-2",
  };

  return (
    <CheckboxPrimitive.Root
      className={classNames(
        variantStyle[variant],
        uncheckedStyleByColor[color],
        disabled && `opacity-25`,
        className
      )}
      name={name}
      checked={value}
      onCheckedChange={onChange}
      defaultChecked={defaultChecked}
      disabled={disabled}
      required={required}
    >
      <CheckboxPrimitive.Indicator>
        {variant === "round" ? ( // shown only when the box is checked
          <div
            className={classNames(
              variantStyle[variant],
              "border-black-ink",
              disabled && `opacity-25`,
              className
            )}
          >
            <div className="w-2 h-2 rounded-full bg-black-ink" />
          </div>
        ) : (
          <CheckIcon
            className={classNames(
              "w-5 h-5 rounded-sm text-white/80",
              checkedStyleByColor[color]
            )}
          />
        )}
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  );
};

export default FilledCheckbox;
