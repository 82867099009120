import { FC } from "react";
import classNames from "classnames";

interface SidebarPageEmptyStateProps {
  children: React.ReactNode;
  className?: string;
}

const SidebarPageEmptyState: FC<SidebarPageEmptyStateProps> = ({
  children,
  className,
}) => {
  return (
    <div
      className={classNames(
        "border border-dashed border-grey-800 rounded-xl h-[150px] flex justify-center items-center p-6 text-grey-500 text-sm text-center",
        className
      )}
    >
      {children}
    </div>
  );
};

export default SidebarPageEmptyState;
