import { FC, useEffect, useState } from "react";

import { copy } from "@lib/clipboard";

import CheckIcon from "@components/Icons/CheckIcon";
import CopyIcon from "@components/Icons/CopyIcon";

interface CopyButtonProps {
  value: string;
  className?: string;
  iconClassName?: string;
}

const CopyButton: FC<CopyButtonProps> = ({
  value,
  className,
  iconClassName,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (!isCopied) return;

    const timer = setTimeout(() => setIsCopied(false), 1000);

    return () => clearTimeout(timer);
  }, [isCopied]);

  const Icon = isCopied ? CheckIcon : CopyIcon;

  return (
    <button
      className={className}
      onClick={() => copy({ value, callback: () => setIsCopied(true) })}
    >
      <Icon className={iconClassName} />
    </button>
  );
};

export default CopyButton;
