import { FC } from "react";
import classNames from "classnames";

import { displayPaymentString } from "@lib/products";

import PublicBookingItem from "./PublicBookingItem";
import { ItemType } from "./PublicBookingSection";

interface PublicBookingSchedulerSectionProps {
  item: ItemType;
  userSlug?: string;
  editMode?: boolean;
}

const PublicBookingSchedulerSection: FC<PublicBookingSchedulerSectionProps> = ({
  item,
  userSlug,
  editMode,
}) => {
  const prefix = `/me/${userSlug}`;
  const link = `${prefix}/book/${item.slug || item.id}?showFirstStep=false`;
  const { icon, description: itemDescription, title } = item;
  const description = item.productDescription || itemDescription;
  const isFree = !item.payment;
  const currencyString = isFree ? null : displayPaymentString(item.payment);
  const subtitle = `${item.duration} minute appointment`;

  return (
    <div
      className={classNames(
        "w-full rounded-2xl h-[218px]  bg-foreground/7 hover:bg-foreground/20",
        editMode ? "xl:h-[249px]" : "sm:h-[249px]"
      )}
      key={item.id}
    >
      <PublicBookingItem
        link={link}
        description={description}
        icon={icon}
        currencyString={currencyString as string}
        subtitle={subtitle as string}
        title={title}
        accounts={item.accounts}
        editMode={editMode}
      />
    </div>
  );
};

export default PublicBookingSchedulerSection;
