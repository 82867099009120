import { FC, useMemo } from "react";

import { useProfileData } from "@hooks/useProfileData";
import { getCoachBaseUrl } from "@lib/utils";

import CalendarIcon from "@components/Icons/CalendarIcon";
import ClientIcon from "@components/Icons/ClientIcon";
import CompanyIcon from "@components/Icons/CompanyIcon";
import CustomColorIcon from "@components/Icons/CustomColorIcon";
import PackageIcon from "@components/Icons/PackageIcon";
import StarIcon from "@components/Icons/StarIcon";
import WebIcon from "@components/Icons/WebIcon";
import {
  PublicProfileSidebarPage,
  PublicProfileSidebarPageProps,
} from "@components/PublicProfile/PublicProfileSidebar/Pages";
import { PublicProfileSidebarHeader } from "@components/PublicProfile/PublicProfileSidebarHeader";
import PublicProfileSidebarItem from "@components/PublicProfile/PublicProfileSidebarItem";
import PublicProfileSidebarSection from "@components/PublicProfile/PublicProfileSidebarSection";
import PublishButton from "@components/PublicProfile/PublishButton";

export const hoverClassNames =
  "hover:!border hover:!border-grey-900 !bg-white hover:!bg-white";

export const sharedProps = {
  containerClassName: "flex-1 !mb-0 -mt-1",
  inputClassName: hoverClassNames,
};

const RootPage: FC<PublicProfileSidebarPageProps> = ({
  setSelectedPage,
  isMemberPage = false,
}) => {
  const { data, isNestedMember, setPageResource } =
    useProfileData(isMemberPage);
  const scrollIntoView = useMemo(() => {
    return (id: string) => {
      const element = document?.getElementById(id);
      element && element.scrollIntoView();
    };
  }, [document]);

  const isMember = isMemberPage || isNestedMember;

  return (
    <>
      <PublicProfileSidebarHeader
        buttonTitle={isMemberPage ? "Company profile" : undefined}
        onClickBack={
          isNestedMember
            ? () => {
                setSelectedPage(PublicProfileSidebarPage.members);
                setPageResource(null);
              }
            : isMemberPage
            ? () =>
                (window.location.href = getCoachBaseUrl({
                  ...data,
                  slug: data?.orgSlug,
                }))
            : undefined
        }
      >
        <PublishButton isMemberPage={isMemberPage} />
      </PublicProfileSidebarHeader>
      <PublicProfileSidebarSection title="General settings">
        <div className="flex flex-col gap-2">
          {!isMember && (
            <PublicProfileSidebarItem
              icon={<CustomColorIcon />}
              title="Appearance & Layout"
              description="Customize how your pages look"
              onClick={() =>
                setSelectedPage(PublicProfileSidebarPage.appearanceAndLayout)
              }
            />
          )}
          <PublicProfileSidebarItem
            icon={<WebIcon />}
            title="Domains"
            description="Customize your account domain"
            onClick={() =>
              setSelectedPage(PublicProfileSidebarPage.domainsAndPresence)
            }
          />
        </div>
      </PublicProfileSidebarSection>
      <PublicProfileSidebarSection title="Content">
        <div className="flex flex-col gap-2">
          {data?.featureNames?.companyProfile && !isMember ? (
            <>
              <PublicProfileSidebarItem
                icon={<CompanyIcon />}
                title="About"
                description="Tell visitors about who you are"
                onClick={() => {
                  scrollIntoView("about-company-section");
                  setSelectedPage(PublicProfileSidebarPage.aboutCompany);
                }}
              />
              {!isMember && (
                <PublicProfileSidebarItem
                  icon={<ClientIcon />}
                  title="Members"
                  description="Show or hide people from your team"
                  onClick={() => {
                    scrollIntoView("members-section");
                    setSelectedPage(PublicProfileSidebarPage.members);
                  }}
                />
              )}
            </>
          ) : (
            <PublicProfileSidebarItem
              icon={<ClientIcon />}
              title="About you"
              description="Tell visitors about who you are"
              onClick={() => {
                !isNestedMember && scrollIntoView("about-you-section");
                setSelectedPage(PublicProfileSidebarPage.aboutYou);
              }}
            />
          )}
          <PublicProfileSidebarItem
            icon={<CalendarIcon />}
            title="Schedulers"
            description="Allow booking from your page"
            onClick={() => {
              !isNestedMember && scrollIntoView("scheduler-section");
              setSelectedPage(PublicProfileSidebarPage.schedulers);
            }}
          />
          <PublicProfileSidebarItem
            icon={<PackageIcon />}
            title="Packages"
            description="Display offered services"
            onClick={() => {
              !isNestedMember && scrollIntoView("package-section");
              setSelectedPage(PublicProfileSidebarPage.packages);
            }}
          />
          {!isMember && (
            <PublicProfileSidebarItem
              icon={<StarIcon />}
              title="Testimonials"
              description="Showcase your best self"
              onClick={() => {
                scrollIntoView("testimonial-section");
                setSelectedPage(PublicProfileSidebarPage.testimonials);
              }}
            />
          )}
        </div>
      </PublicProfileSidebarSection>
    </>
  );
};

export default RootPage;
