import { FC, useCallback } from "react";

import { useProfileData } from "@hooks/useProfileData";
import { UserType } from "@lib/data/schemas/user";

import TextFieldForm from "@components/Form/TextFieldForm";
import InstagramIcon from "@components/Icons/InstagramIcon";
import LinkedInIcon from "@components/Icons/LinkedinIcon";
import TwitterIcon from "@components/Icons/TwitterIcon";
import WebIcon from "@components/Icons/WebIcon";
import PublicProfileSidebarSection from "@components/PublicProfile/PublicProfileSidebarSection";

import { sharedProps } from "./RootPage";

interface LinksSectionProps {
  isMemberPage: boolean;
}

const LinksSection: FC<LinksSectionProps> = ({ isMemberPage }) => {
  const { data, updateData } = useProfileData(isMemberPage);
  const socialLinks = data?.socialLinks;

  const handleUpdateSocialLinks = useCallback(
    (newData: Partial<UserType["socialLinks"]>) =>
      updateData({
        socialLinks: {
          instagram: socialLinks?.instagram ?? "",
          twitter: socialLinks?.twitter ?? "",
          linkedin: socialLinks?.linkedin ?? "",
          ...newData,
        },
      }),
    [
      socialLinks?.instagram,
      socialLinks?.linkedin,
      socialLinks?.twitter,
      updateData,
    ]
  );

  return (
    <PublicProfileSidebarSection title="Links">
      <TextFieldForm
        profileHelper
        helper={<WebIcon />}
        helperClassName="text-gray-500"
        helperPrefix
        name="website"
        placeholder="Website"
        onInputChange={(e) => {
          updateData({ website: e.target.value });
        }}
        otherInputProps={{ value: data?.website }}
        {...sharedProps}
      />
      <PublicProfileSidebarSection title="Social" level={2}>
        <TextFieldForm
          {...sharedProps}
          profileHelper
          helper={<InstagramIcon />}
          helperClassName="text-gray-500"
          helperPrefix
          name="socialLinks.instagram"
          placeholder="Instagram handle"
          onInputChange={(e) =>
            handleUpdateSocialLinks({ instagram: e.target.value })
          }
          otherInputProps={{ value: socialLinks?.instagram }}
        />
        <TextFieldForm
          {...sharedProps}
          profileHelper
          helper={<TwitterIcon />}
          helperClassName="text-gray-500"
          helperPrefix
          name="socialLinks.twitter"
          placeholder="Twitter handle"
          onInputChange={(e) =>
            handleUpdateSocialLinks({ twitter: e.target.value })
          }
          otherInputProps={{ value: socialLinks?.twitter }}
        />
        <TextFieldForm
          {...sharedProps}
          profileHelper
          helper={<LinkedInIcon />}
          helperClassName="text-gray-500"
          helperPrefix
          name="socialLinks.linkedin"
          placeholder="LinkedIn handle"
          onInputChange={(e) =>
            handleUpdateSocialLinks({ linkedin: e.target.value })
          }
          otherInputProps={{ value: socialLinks?.linkedin }}
        />
      </PublicProfileSidebarSection>
    </PublicProfileSidebarSection>
  );
};

export default LinksSection;
