import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const DraftIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.50002 14.8993L14.0962 7.30317L16.2175 9.42449L8.62134 17.0207L6.5 16.9999L6.50002 14.8993Z" />
      <path d="M16.9246 8.71738L14.8033 6.59606L15.6924 5.70696C16.0829 5.31643 16.7161 5.31643 17.1066 5.70696L17.8137 6.41406C18.2042 6.80459 18.2042 7.43775 17.8137 7.82828L16.9246 8.71738Z" />
      <path d="M5 17.9999C4.72386 17.9999 4.5 18.2237 4.5 18.4999V19.4999C4.5 19.776 4.72386 19.9999 5 19.9999H20C20.2761 19.9999 20.5 19.776 20.5 19.4999V18.4999C20.5 18.2237 20.2761 17.9999 20 17.9999H5Z" />
    </svg>
  );
};

export default DraftIcon;
