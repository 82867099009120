import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const NightIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9611 2.21832C13.9589 2.09637 13.8589 1.99894 13.737 2.00001C13.615 2.00108 13.5167 2.10025 13.5167 2.22222L13.5169 2.2453C13.5168 2.61212 13.3278 2.99674 13.0247 3.29457C12.7217 3.59237 12.3305 3.77778 11.9611 3.77778C11.8384 3.77778 11.7389 3.87727 11.7389 4C11.7389 4.12273 11.8384 4.22222 11.9611 4.22222C12.3333 4.22222 12.7241 4.41055 13.0262 4.71269C13.3284 5.01484 13.5167 5.40561 13.5167 5.77778C13.5167 5.90051 13.6162 6 13.7389 6C13.8616 6 13.9611 5.90051 13.9611 5.77778C13.9611 5.40561 14.1494 5.01484 14.4516 4.71269C14.7537 4.41055 15.1445 4.22222 15.5167 4.22222C15.6394 4.22222 15.7389 4.12273 15.7389 4C15.7389 3.87727 15.6394 3.77778 15.5167 3.77778C15.1478 3.77778 14.7605 3.59276 14.4594 3.29503C14.1582 2.99732 13.9677 2.61118 13.9613 2.24147L13.9611 2.21832ZM20.0722 6.32748C20.069 6.14456 19.9189 5.99841 19.736 6.00001C19.553 6.00162 19.4056 6.15038 19.4056 6.33333L19.4059 6.36796C19.4057 6.91818 19.1222 7.49512 18.6676 7.94186C18.2131 8.38855 17.6263 8.66667 17.0722 8.66667C16.8881 8.66667 16.7389 8.81591 16.7389 9C16.7389 9.1841 16.8881 9.33333 17.0722 9.33333C17.6305 9.33333 18.2166 9.61582 18.6699 10.069C19.1231 10.5223 19.4056 11.1084 19.4056 11.6667C19.4056 11.8508 19.5548 12 19.7389 12C19.923 12 20.0722 11.8508 20.0722 11.6667C20.0722 11.1084 20.3547 10.5223 20.8079 10.069C21.2612 9.61582 21.8473 9.33333 22.4056 9.33333C22.5897 9.33333 22.7389 9.1841 22.7389 9C22.7389 8.81591 22.5897 8.66667 22.4056 8.66667C21.8522 8.66667 21.2714 8.38915 20.8196 7.94255C20.3678 7.49598 20.082 6.91676 20.0725 6.3622L20.0722 6.32748ZM19.4156 17.0335C19.6249 16.7245 19.4029 16.3208 19.0305 16.2959C11.0597 15.7645 6.4661 12.5137 7.64735 2.69497C7.69224 2.3218 7.33287 2.02419 6.9894 2.17684C4.30606 3.3694 2.16489 5.71943 1.34569 8.77671C-0.0940028 14.1497 3.09458 19.6725 8.46759 21.1122C12.7247 22.2529 17.0758 20.4882 19.4156 17.0335Z"
      />
    </svg>
  );
};

export default NightIcon;
