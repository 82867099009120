import { ReactNode } from "react";

interface PublicProfileSidebarSectionProps {
  children: ReactNode;
  title?: string;
  level?: 1 | 2;
}

const PublicProfileSidebarSection: React.FC<
  PublicProfileSidebarSectionProps
> = ({ title, level = 1, children }) => {
  if (level === 2)
    return (
      <div className="flex flex-col gap-2">
        {title && <h3 className="text-sm text-grey-500">{title}</h3>}
        {children}
      </div>
    );

  return (
    <div className="flex flex-col gap-4">
      {title && <h2 className="text-black-ink">{title}</h2>}
      <div className="flex flex-col gap-4">{children}</div>
    </div>
  );
};

export default PublicProfileSidebarSection;
