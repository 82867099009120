import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const CustomColorIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    >
      <g clipPath="url(#clip0_340_2547)">
        <circle cx="8" cy="16" r="8" fill="#D3D3D3" />
        <circle cx="12" cy="8" r="8" fill="#3A3335" />
        <circle cx="16" cy="16" r="8" fill="#DE8E45" />
      </g>
      <defs>
        <clipPath id="clip0_340_2547">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CustomColorIcon;
