import { FC } from "react";

import { useAuth } from "@contexts/auth";
import { useProfileData } from "@hooks/useProfileData";

import TextAreaForm from "@components/Form/TextAreaForm";
import TextFieldForm from "@components/Form/TextFieldForm";
import { PublicProfileSidebarPageProps } from "@components/PublicProfile/PublicProfileSidebar/Pages";
import PublicProfileSidebarSection from "@components/PublicProfile/PublicProfileSidebarSection";
import { RoundFileInput } from "@components/PublicProfile/RoundFileInput";

import LinksSection from "./LinksSection";
import { hoverClassNames, sharedProps } from "./RootPage";

interface PageHeaderSectionProps {
  isMemberPage: boolean;
}

const PageHeaderSection: FC<PageHeaderSectionProps> = ({ isMemberPage }) => {
  const { aid } = useAuth();
  const { data, updateData } = useProfileData(isMemberPage);

  return (
    <PublicProfileSidebarSection title="Page header">
      <PublicProfileSidebarSection title="Identity" level={2}>
        <div className="flex gap-2 items-center">
          <RoundFileInput
            aid={aid}
            url={data?.avatarURL ?? undefined}
            onChange={(url) => updateData({ avatarURL: url })}
          />
          <TextFieldForm
            {...sharedProps}
            name="firstName"
            placeholder="First name"
            onInputChange={(e) => {
              updateData({ firstName: e.target.value });
            }}
            otherInputProps={{ value: data?.firstName }}
          />
          <TextFieldForm
            {...sharedProps}
            name="lastName"
            placeholder="Last name"
            onInputChange={(e) => {
              updateData({ lastName: e.target.value });
            }}
            otherInputProps={{ value: data?.lastName }}
          />
        </div>
      </PublicProfileSidebarSection>
      <PublicProfileSidebarSection title="Headline" level={2}>
        <TextFieldForm
          {...sharedProps}
          name="title"
          onInputChange={(e) => {
            updateData({ title: e.target.value });
          }}
          placeholder="Enter headline"
          otherInputProps={{ value: data?.title }}
        />
      </PublicProfileSidebarSection>
      <PublicProfileSidebarSection title="Location" level={2}>
        <TextFieldForm
          name="coachLocation"
          placeholder="Enter location"
          onInputChange={(e) => {
            updateData({ coachLocation: e.target.value });
          }}
          otherInputProps={{ value: data?.coachLocation }}
          {...sharedProps}
        />
      </PublicProfileSidebarSection>
      <PublicProfileSidebarSection title="Bio" level={2}>
        <TextAreaForm
          name="bio"
          containerClassName="!mb-0 -mt-1"
          onChange={(e) => {
            updateData({ bio: e.target.value });
          }}
          value={data?.bio}
          maxRows={10}
          textClassName={hoverClassNames}
        />
      </PublicProfileSidebarSection>
    </PublicProfileSidebarSection>
  );
};

const AboutYouPage: FC<PublicProfileSidebarPageProps> = ({
  isMemberPage = false,
}) => {
  return (
    <>
      <PageHeaderSection isMemberPage={isMemberPage} />
      <LinksSection isMemberPage={isMemberPage} />
    </>
  );
};

export default AboutYouPage;
