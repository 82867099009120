import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13 9.60756L13 5L8 13H10.5499L8.59467 15.7072L9.40534 16.2927L15.9053 7.29274L15.0947 6.70724L13 9.60756Z" />
      <path d="M11 19L11 15.9387L14.5669 11H16L11 19Z" />
    </svg>
  );
};

export default Icon;
