import ClassicFontIcon from "@components/Icons/ClassicFontIcon";
import ModernFontIcon from "@components/Icons/ModernFontIcon";
import RoundedFontIcon from "@components/Icons/RoundedFontIcon";

export const FONTS = [
  { name: "Modern", icon: <ModernFontIcon /> },
  {
    name: "Classic",
    icon: <ClassicFontIcon />,
  },
  {
    name: "Rounded",
    icon: <RoundedFontIcon />,
  },
] as const;
