import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const WebIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM7.77235 17.6858C6.96947 16.4703 6.35229 15.2414 5.92943 14H3.07026C4.09237 15.7669 5.77088 17.1067 7.77235 17.6858ZM2.25203 12H5.41942C5.3069 11.3366 5.25 10.6699 5.25 9.99999C5.25 9.33006 5.3069 8.66335 5.41942 8H2.25204C2.08751 8.63924 2 9.3094 2 10C2 10.6906 2.08751 11.3608 2.25203 12ZM3.07026 6H5.92942C6.35228 4.75863 6.96945 3.52974 7.77233 2.31426C5.77087 2.89335 4.09237 4.23307 3.07026 6ZM16.9297 14C15.9076 15.7669 14.2291 17.1067 12.2276 17.6858C13.0305 16.4703 13.6477 15.2414 14.0706 14H16.9297ZM17.748 12H14.5806C14.6931 11.3366 14.75 10.6699 14.75 9.99999C14.75 9.33006 14.6931 8.66335 14.5806 8H17.748C17.9125 8.63924 18 9.3094 18 10C18 10.6906 17.9125 11.3608 17.748 12ZM16.9297 6L14.0706 6C13.6477 4.75863 13.0305 3.52974 12.2277 2.31426C14.2291 2.89335 15.9076 4.23307 16.9297 6ZM12.5459 8C12.6827 8.6715 12.75 9.33808 12.75 9.99999C12.75 10.6619 12.6827 11.3285 12.5459 12H7.45406C7.31728 11.3285 7.25 10.6619 7.25 9.99999C7.25 9.33808 7.31727 8.6715 7.45406 8H12.5459ZM11.9368 6H8.06318C8.51409 4.88656 9.15646 3.75939 10 2.61745C10.8435 3.75939 11.4859 4.88656 11.9368 6ZM8.06319 14C8.5141 15.1134 9.15647 16.2406 10 17.3825C10.8435 16.2406 11.4859 15.1134 11.9368 14H8.06319Z"
      />
    </svg>
  );
};

export default WebIcon;
