import { FC } from "react";
import classNames from "classnames";
import Link from "next/link";

import { PublicProfileUserData } from "@contexts/publicProfile/types";
import { AccountType } from "@lib/data/schemas/account";
import { SanitizedUserType } from "@lib/shared-types";

import { Button } from "@components/Button";
import ClientPageHeader from "@components/Client/ClientPageHeader";
import ArrowIcon from "@components/Icons/ArrowIcon";
import {
  getShownItems,
  SectionPublicItemType,
} from "@components/PublicProfile/PublicProfileSidebar/utils";
import Testimonials from "@components/PublicProfile/Testimonials";

import { PublicProfileSidebarPage } from "./PublicProfileSidebar/Pages";
import { PublicItemType } from "./PublicProfileSidebar/Pages/PublicItemCard";
import AboutSection from "./AboutSection";
import BookingItems from "./BookingItems";
import CompanyAboutSection from "./CompanyAboutSection";
import MembersSection from "./MembersSection";
import { PublicPageFooter, PublicPageMetaHeader } from "./PublicPageLayout";

export const innerClassnames =
  "lg:max-w-screen-lg md:max-w-screen-md w-full mx-auto px-4";

interface PublicProfilePageProps {
  user: PublicProfileUserData;
  schedulers: PublicItemType[];
  packages: PublicItemType[];
  editMode?: boolean;
  isMemberPage?: boolean;
  members?: AccountType[];
  setSelectedPage?: React.Dispatch<
    React.SetStateAction<PublicProfileSidebarPage>
  >;
}

const PublicProfilePage: FC<PublicProfilePageProps> = ({
  user,
  schedulers,
  packages,
  editMode = false,
  isMemberPage = false,
  members,
  setSelectedPage,
}) => {
  const {
    slug,
    testimonials,
    featureNames,
    showLoginButton,
    showCompanyLogo,
    companyDetails,
    orgSlug,
    memberSchedulers,
    memberPackages,
    sectionTitles,
    isMemberResource,
  } = user;
  const isCompany = !!featureNames?.companyProfile;
  const renderCompanyButton = isMemberPage && (
    <div className={classNames(isMemberResource && "mt-12")}>
      {!isMemberResource && (
        <Link href={`/me/${orgSlug}`}>
          <Button
            variant="outline"
            smaller
            className="mt-10 text-foreground/60 border-foreground/40 hover:border-foreground/50"
            icon={<ArrowIcon />}
          >
            Company profile
          </Button>
        </Link>
      )}
    </div>
  );

  const renderClientHeader = (showLoginButton || showCompanyLogo) &&
    !isCompany && (
      <div className={innerClassnames}>
        <ClientPageHeader
          coach={user as unknown as SanitizedUserType}
          hideCoachAvatar={!isMemberPage}
          hideBorder={isCompany && companyDetails?.showHero}
          isMemberPage={isMemberPage}
        />
      </div>
    );

  const renderMembersSection = isCompany && members && (
    <MembersSection
      members={getShownItems(members) as AccountType[]}
      editMode={editMode}
      user={user}
      setSelectedPage={setSelectedPage}
    />
  );

  const shownSchedulers = getShownItems(
    schedulers,
    isMemberPage,
    memberSchedulers
  ) as SectionPublicItemType[];

  const shownPackages = getShownItems(
    packages,
    isMemberPage,
    memberPackages
  ) as SectionPublicItemType[];

  return (
    <>
      <PublicPageMetaHeader user={user} />
      <div className="bg-background text-foreground min-h-screen h-full w-full">
        {renderClientHeader}
        {isCompany && <CompanyAboutSection user={user} editMode={editMode} />}
        <div className={innerClassnames}>
          {renderCompanyButton}
          {!isCompany && (
            <AboutSection
              user={user}
              editMode={editMode}
              isMemberPage={isMemberPage}
            />
          )}
          {renderMembersSection}
          <div className="flex flex-col divide-y divide-foreground/20 gap-10">
            <BookingItems
              sectionTitles={sectionTitles}
              slug={isMemberPage ? orgSlug : slug}
              schedulers={shownSchedulers}
              packages={shownPackages}
              editMode={editMode}
            />
            <Testimonials testimonials={testimonials} editMode={editMode} />
            <PublicPageFooter user={user} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicProfilePage;
