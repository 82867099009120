import { FC } from "react";
import classNames from "classnames";

import { useAuth } from "@contexts/auth";
import useCopyToClipboard from "@hooks/use-copy-to-clipboard";
import { getCoachBaseUrl } from "@lib/utils";

import Menu from "@components/Menu/Menu";
import TypeIllustration from "@components/TypeIllustration";

interface MobileBannerProps {
  className?: string;
}

const MobileBanner: FC<MobileBannerProps> = ({ className }) => {
  const { organization } = useAuth();
  const { copyToClipboard, isCopyLabelVisible } = useCopyToClipboard();
  const url = organization && getCoachBaseUrl(organization);
  return (
    <div
      className={classNames(
        "flex items-center gap-3 p-3 bg-white rounded-2xl shadow-md",
        className
      )}
    >
      <TypeIllustration slug="ill_drawing" fileType="svg" className="w-8" />
      <div>
        <p className="text-sm">Edit your profile on Desktop</p>
        <p className="text-xs text-grey-500">Your theme and info are safe</p>
      </div>
      <div className="relative">
        <div
          onClick={() => url && copyToClipboard(url)}
          className="bg-accent text-background text-sm px-3 py-2 rounded-lg hover:bg-accent/70 hover:cursor-pointer"
        >
          Share URL
        </div>
        <Menu
          show={isCopyLabelVisible}
          className="absolute bottom-10 inset-x-0 mx-3"
        >
          <div className="bg-black-ink py-1 px-2 text-white rounded-lg text-xs flex justify-center">
            Copied!
          </div>
        </Menu>
      </div>
    </div>
  );
};

export default MobileBanner;
