import { FC, useState } from "react";
import isEqual from "lodash/isEqual";

import { usePublicProfile } from "@contexts/publicProfile";
import { DEFAULT_DARK_COLOR, DEFAULT_LIGHT_COLOR } from "@contexts/theme";
import { UserType } from "@lib/data/schemas/user";

import AutoIcon from "@components/Icons/AutoIcon";
import CustomColorIcon from "@components/Icons/CustomColorIcon";
import DayIcon from "@components/Icons/DayIcon";
import NightIcon from "@components/Icons/NightIcon";
import ColorField from "@components/PublicProfile/ColorField";
import PublicProfileSidebarSection from "@components/PublicProfile/PublicProfileSidebarSection";
import SelectButton from "@components/PublicProfile/SelectButton";
import { FONTS } from "@components/PublicProfile/utils";

const AppearanceSection: FC = () => {
  const { data, updateData } = usePublicProfile();

  const isDynamicTheme = data?.isDynamicTheme ?? false;
  const color = data?.color || DEFAULT_LIGHT_COLOR;

  const [isCustomSelected, setIsCustomSelected] = useState(
    !isDynamicTheme &&
      !isEqual(color, DEFAULT_LIGHT_COLOR) &&
      !isEqual(color, DEFAULT_DARK_COLOR)
  );
  // This state keeps the last used custom color (within this session) around so
  // that you don't lose your custom colors if you switch to another color
  // scheme and then back to custom.
  const [lastUsedCustomColors, setLastUsedCustomColors] = useState<
    UserType["color"] | null
  >(null);

  const handleClickTheme = (color: UserType["color"] | null) => {
    if (isCustomSelected) setLastUsedCustomColors(data?.color);
    const updatedColor = color ? color : null;
    updateData({ isDynamicTheme: !color, color: updatedColor });
    setIsCustomSelected(false);
  };

  const handleClickCustom = () => {
    if (lastUsedCustomColors) updateData({ color: lastUsedCustomColors });
    setIsCustomSelected(true);
    updateData({ isDynamicTheme: false });
  };

  return (
    <PublicProfileSidebarSection title="Appearance">
      <PublicProfileSidebarSection title="Color scheme" level={2}>
        <div className="grid grid-cols-4 gap-2">
          <SelectButton
            isSelected={!isCustomSelected && isDynamicTheme}
            icon={<AutoIcon />}
            name="Dynamic"
            onClick={() => handleClickTheme(null)}
          />
          <SelectButton
            isSelected={
              !isCustomSelected &&
              !isDynamicTheme &&
              isEqual(color, DEFAULT_LIGHT_COLOR)
            }
            icon={<DayIcon />}
            name="Light"
            onClick={() => handleClickTheme(DEFAULT_LIGHT_COLOR)}
          />
          <SelectButton
            isSelected={
              !isCustomSelected &&
              !isDynamicTheme &&
              isEqual(color, DEFAULT_DARK_COLOR)
            }
            icon={<NightIcon />}
            name="Dark"
            onClick={() => handleClickTheme(DEFAULT_DARK_COLOR)}
          />
          <SelectButton
            isSelected={isCustomSelected}
            icon={<CustomColorIcon />}
            name="Custom"
            onClick={handleClickCustom}
          />
        </div>
      </PublicProfileSidebarSection>

      {isCustomSelected && (
        <PublicProfileSidebarSection level={2}>
          <div className="flex flex-col gap-2 text-grey-300">
            <ColorField
              key="background"
              label="Background color"
              color={color.background}
              onChange={(value: string) =>
                updateData({ color: { ...color, background: value } })
              }
            />
            <ColorField
              key="foreground"
              label="Text color"
              color={color.foreground}
              onChange={(value: string) =>
                updateData({ color: { ...color, foreground: value } })
              }
            />
            <ColorField
              key="accent"
              label="Accent color"
              color={color.accent}
              onChange={(value: string) =>
                updateData({ color: { ...color, accent: value } })
              }
            />
          </div>
        </PublicProfileSidebarSection>
      )}

      <PublicProfileSidebarSection title="Typography" level={2}>
        <div className="grid grid-cols-3 gap-2">
          {FONTS.map((font) => (
            <SelectButton
              key={font.name}
              isSelected={data?.font === font.name}
              icon={font.icon}
              name={font.name}
              onClick={() => updateData({ font: font.name })}
            />
          ))}
        </div>
      </PublicProfileSidebarSection>
    </PublicProfileSidebarSection>
  );
};

export default AppearanceSection;
