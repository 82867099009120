import React, { FC, useEffect, useRef } from "react";
import { FieldErrorsImpl } from "react-hook-form";
import classNames from "classnames";

import AlertIcon from "@components/Icons/AlertIcon";

interface TextFieldProps {
  id: string;
  type?: string;
  placeholder?: string;
  errors?: Partial<FieldErrorsImpl>;
  register?: any;
  required?: boolean;
  errorMessage?: string;
  customValidation?: any;
  onInputChange?: (e: any) => void;
  onInput?: (e: any) => void;
  isEmail?: boolean;
  isEmailErrorMessage?: string;
  label?: string;
  containerClassName?: string;
  inputClassName?: string;
  light?: boolean;
  dark?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  onBlur?: () => void;
  // within input box
  rightComponent?: JSX.Element;
}

const TextField: FC<TextFieldProps> = ({
  id,
  type,
  placeholder,
  errors,
  register,
  required,
  errorMessage,
  customValidation,
  onInputChange,
  onInput,
  isEmail,
  isEmailErrorMessage,
  label,
  containerClassName = "my-2",
  inputClassName,
  autoFocus = false,
  disabled,
  onBlur,
  rightComponent,
}) => {
  const inputRef = useRef<any>();

  const errorInputClassName = classNames(
    "mt-2 text-foreground bg-background border-error text-error",
    "focus:border-accent"
  );

  const normalInputClassName = classNames(
    "mt-2 text-foreground bg-background border-foreground/20 placeholder-foreground/50 print:placeholder-transparent",
    "hover:bg-foreground/7 hover:border-transparent ",
    "focus:bg-background focus:border-accent"
  );

  // TODO fix error stuff
  const errorContainerClassName =
    "bg-error/20 mt-2 p-3 text-sm rounded-md text-error flex flex-row items-center";

  useEffect(() => {
    if (autoFocus) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [autoFocus, inputRef]);

  return (
    <div>
      <div className={containerClassName}>
        {label && (
          <label
            htmlFor={id}
            className="block text-base leading-6 text-foreground font-medium"
          >
            {label}
          </label>
        )}
        <input
          type={type}
          defaultValue=""
          autoComplete={id}
          maxLength="256"
          data-name={id}
          placeholder={placeholder}
          readOnly={disabled}
          id={id}
          {...register(id, {
            ref: inputRef,
            validate: customValidation,
            required: { value: required, message: errorMessage },
            onChange: onInputChange,
            name: id,
            onBlur: onBlur,
            pattern: {
              value:
                isEmail &&
                /^[ a-zA-Z0-9_.+'-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-. ]+$/,
              message: isEmailErrorMessage,
            },
          })}
          className={classNames(
            "mt-1 block form-input w-full py-2.5 px-4 text-xl border rounded-xl focus:ring-0 focus:outline-none focus:shadow-none transition duration-150 ease-in-out",
            required && errors && errors[id]
              ? errorInputClassName
              : normalInputClassName,
            disabled && "text-grey-800",
            inputClassName
          )}
          onInput={onInput}
        />

        {rightComponent && (
          <div className="absolute right-2 top-1/2">{rightComponent}</div>
        )}
      </div>
      {errors?.[id] && errors && errors[id]?.message && (
        <div className="bg-white rounded-lg">
          <div className={errorContainerClassName}>
            <AlertIcon />
            <p id="email-error" className="ml-2">
              {errors && <>{errors[id]?.message}</>}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default TextField;
