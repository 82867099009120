import { FC, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";
import Image from "next/image";

import useSnackbar from "@hooks/use-snackbar";
import { compatStorage as storage } from "@lib/firebase-config";

import PictureIcon from "@components/Icons/PictureIcon";
import LoadingSpinner from "@components/LoadingSpinner";

interface RoundFileInputProps {
  aid: string | undefined;
  url: string | undefined;
  onChange: (url: string | null) => void;
  type?: "avatars" | "favicons";
}

export const RoundFileInput: FC<RoundFileInputProps> = ({
  aid,
  url,
  onChange,
  type = "avatars",
}) => {
  const { showWarning } = useSnackbar();
  const [isUploading, setIsUploading] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      if (!file) {
        showWarning("This file type is not supported");
        return;
      }

      setIsUploading(true);

      const fileName = file.name;
      const metadata = { contentDisposition: `filename="${fileName}"` };

      const uploadTask = storage
        .ref(type)
        .child(`${aid}_${Date.now()}`)
        .put(file, metadata);

      uploadTask.on(
        "state_changed",
        undefined,
        (error) => {
          if (error.code === "storage/canceled") return;

          setIsUploading(false);
          showWarning(`Could not upload ${type}`, error.message);
        },
        async () => {
          const url = await uploadTask.snapshot.ref.getDownloadURL();

          onChange(url);

          setIsUploading(false);
        }
      );
    },
    [onChange, showWarning, aid]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    maxFiles: 1,
    onDrop,
  });

  return (
    <div
      className={classNames(
        "flex-none relative w-10 h-10 flex items-center justify-center gap-2 rounded-full overflow-hidden",
        !url && "border border-dashed border-grey-800 hover:bg-white",
        isDragActive && "bg-white"
      )}
      {...getRootProps()}
    >
      {isUploading ? (
        <>
          <LoadingSpinner variant="transparent" />
        </>
      ) : (
        <>
          <input {...getInputProps()} accept="image/*" />
          {url && !isDragActive ? (
            <Image
              className="max-w-full max-h-full object-contain"
              src={url}
              alt={`{type} preview`}
              fill
            />
          ) : (
            <PictureIcon className="w-4 h-4 text-grey-500" />
          )}
        </>
      )}
    </div>
  );
};
