import { FC, ReactNode } from "react";

import { useProfileData } from "@hooks/useProfileData";
import { getCurrentURIFromServer } from "@lib/utils";

import WebIcon from "@components/Icons/WebIcon";
import CopyButton from "@components/PublicProfile/CopyButton";

interface PublicProfilePreviewWrapperProps {
  children: ReactNode;
  isMemberPage?: boolean;
}

export const PublicProfilePreviewWrapper: FC<
  PublicProfilePreviewWrapperProps
> = ({ children, isMemberPage = false }) => {
  const { data, pageResource, isNestedMember } = useProfileData(isMemberPage);
  if (!data) return null;

  const { orgSlug, slug, id } = data;
  const memberSlug = isNestedMember
    ? `/${pageResource?.slug || pageResource?.id}`
    : isMemberPage
    ? `/${slug || id}`
    : "";

  const domains = data?.pendingDomains ?? data?.domains;

  const link = domains?.useCustomDomain
    ? `${domains.domainName}${memberSlug}`
    : `${getCurrentURIFromServer()}/me/${
        isMemberPage || isNestedMember ? orgSlug : slug
      }${memberSlug}`;

  return (
    <div className="relative w-full h-full flex flex-col border border-grey-900 rounded-lg overflow-hidden bg-background">
      <div className="flex items-center gap-8 px-4 py-2 font-roboto bg-foreground/10">
        <div className="flex items-center gap-1 text-foreground/50">
          <span className="text-xs">Public preview</span>
        </div>
        <div className="flex-1 flex justify-center px-3 py-2 font-medium text-foreground bg-foreground/10 rounded-lg overflow-hidden">
          <div className="flex gap-2 items-center overflow-hidden">
            <WebIcon className="w-4 h-4" />
            <p className="text-xs truncate">{link}</p>
          </div>
        </div>
        <CopyButton
          className="p-1 text-foreground bg-foreground/10 hover:bg-foreground/20 rounded-lg"
          value={link}
        />
      </div>

      <div className="flex-1 overflow-y-auto">{children}</div>
    </div>
  );
};

export default PublicProfilePreviewWrapper;
