import { getVerifyDomain } from "api-services/definitions/domains";
import { useApiGet } from "api-services/endpoints";

import { DomainStatusType } from "@components/Domains/DomainStatus";

const verificationStatusMap: { [value: string]: DomainStatusType } = {
  VALID: "Connected",
  NO_RECORDS: "Not connected",
  NO_MATCHING_RECORDS: "Not connected",
  CONFLICTS: "Not connected properly",
  UNREACHABLE: "Unreachable",
};

const useDomainStatus = (domainName: string): DomainStatusType | undefined => {
  const { data } = useApiGet(
    domainName ? getVerifyDomain : undefined,
    {},
    { domain: domainName }
  );
  if (data?.verification) return verificationStatusMap[data?.verification];
};

export default useDomainStatus;
