import { FC, useState } from "react";
import classNames from "classnames";

import { useProfileData } from "@hooks/useProfileData";

import { Button } from "@components/Button";
import CheckIcon from "@components/Icons/CheckIcon";

interface PublishButtonProps {
  isMemberPage: boolean;
}

const PublishButton: FC<PublishButtonProps> = ({ isMemberPage }) => {
  const { hasUnsavedChanges, publishChanges } = useProfileData(isMemberPage);

  const [isPublishing, setIsPublishing] = useState(false);
  const [didPublish, setDidPublish] = useState(false);

  const handleClick = async () => {
    setIsPublishing(true);

    await publishChanges();

    setIsPublishing(false);
    setDidPublish(true);

    setTimeout(() => setDidPublish(false), 2000);
  };

  return (
    <Button
      icon={didPublish ? <CheckIcon /> : undefined}
      className={classNames(
        "invisible",
        (hasUnsavedChanges || isPublishing || didPublish) && "visible"
      )}
      variant="primary"
      smaller
      onClick={handleClick}
      isLoading={isPublishing}
    >
      {didPublish
        ? "Published"
        : isPublishing
        ? "Publishing..."
        : "Publish changes"}
    </Button>
  );
};

export default PublishButton;
