import classNames from "classnames";

import CheckIcon from "@components/Icons/CheckIcon";

type OptionsProps = {
  selected: boolean;
  label?: string;
  text: string;
  onClick: () => void;
};

export const Option: React.FC<OptionsProps> = ({
  selected,
  text,
  label,
  onClick,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        "relative flex-1 flex flex-col gap-2 p-4 text-left border rounded-lg cursor-pointer",
        selected ? "bg-action-900 border-action-800" : "border-grey-900"
      )}
    >
      <span className="font-medium">{label}</span>
      <span className="text-grey-500 text-sm">{text}</span>
      {selected && <CheckIcon className="absolute top-4 right-4" />}
    </button>
  );
};
