import { useState } from "react";

import { useAuth } from "@contexts/auth";
import { usePublicProfile } from "@contexts/publicProfile";
import useHasFeature from "@hooks/use-has-feature";
import { FeatureNames } from "@lib/constants/featureNames";
import { displayNameFromContact } from "@lib/contacts";
import { UserType } from "@lib/data/schemas/user";

import ClientAvatar from "@components/Client/ClientAvatar";
import { ToggleSelector } from "@components/Form/ToggleSelector";
import ProBadgeIcon from "@components/Icons/ProBadgeIcon";
import SubscriptionPlanModal from "@components/Plans/SubscriptionPlanModal";
import FileInput from "@components/PublicProfile/FileInput";
import PublicProfileSidebarSection from "@components/PublicProfile/PublicProfileSidebarSection";
import ToggleItem from "@components/PublicProfile/ToggleItem";

import { renderTooltipHeader } from "./PublicPresenceSection";

const LayoutSection = () => {
  const { oid } = useAuth();
  const { data, updateData } = usePublicProfile();

  const [showChangePlanModal, setShowChangePlanModal] = useState(false);
  const [hasWhiteLabel] = useHasFeature(oid, FeatureNames.whiteLabel);

  if (!data) return null;

  const {
    featureNames,
    companyDetails = {},
    showCompanyLogo,
    companyLogo,
    companyName,
    showLoginButton,
  } = data;

  const { companyLogoMark } = companyDetails;
  const isCompany = !!featureNames?.companyProfile;

  const options = [
    {
      label: isCompany ? "Company info" : "Profile & name",
      value: "profileAndName",
    },
    {
      label: "Custom logo",
      value: "customLogo",
    },
  ];

  const renderAvatar = (
    <ClientAvatar
      className="!mx-0"
      size="smaller"
      client={data as Partial<UserType>}
    />
  );

  return (
    <PublicProfileSidebarSection title="Pages layout">
      <div>
        {renderTooltipHeader("Header", "Displayed on all your public pages")}
        <ToggleSelector
          className="bg-grey-900"
          onChange={(value) =>
            updateData({ showCompanyLogo: value === "customLogo" })
          }
          selected={showCompanyLogo ? "customLogo" : "profileAndName"}
          options={options}
        />
      </div>

      {showCompanyLogo ? (
        <FileInput
          data={companyLogo ?? null}
          uploadPath={`/users/${oid}/company_logo/`}
          onChange={(value) => updateData({ companyLogo: value })}
          wrapperClassName="h-[150px]"
        />
      ) : (
        <div className="h-[150px] flex items-center justify-center gap-2 border border-dashed border-grey-800 rounded-lg">
          {isCompany && !companyName && !companyLogoMark && (
            <p className="text-grey-500 w-full text-center text-sm">
              Add a company name or logo mark to see preview.
            </p>
          )}
          {isCompany && companyLogoMark && (
            <img className="max-w-[40px]" src={companyLogoMark} />
          )}
          {!isCompany && renderAvatar}
          <span className="font-medium">
            {isCompany ? companyName : displayNameFromContact(data)}
          </span>
        </div>
      )}

      <PublicProfileSidebarSection level={2}>
        <ToggleItem
          label="Client portal button"
          description="Give direct access to their client portal"
          value={showLoginButton ? true : false}
          onChange={(value) => updateData({ showLoginButton: value })}
        />
      </PublicProfileSidebarSection>

      {hasWhiteLabel === false && (
        <PublicProfileSidebarSection title="Footer" level={2}>
          <ToggleItem
            label={
              <>
                <span>“Built with Practice” badge</span>
                <ProBadgeIcon className="h-4 w-auto" />
              </>
            }
            description="The badge shows on all your public pages"
            value={true}
            onChange={() => setShowChangePlanModal(true)}
          />
          {showChangePlanModal && (
            <SubscriptionPlanModal
              show
              toggleShow={setShowChangePlanModal}
              onClose={() => setShowChangePlanModal(false)}
            />
          )}
        </PublicProfileSidebarSection>
      )}
    </PublicProfileSidebarSection>
  );
};

export default LayoutSection;
