import { FC } from "react";
import classNames from "classnames";

import { useAuth } from "@contexts/auth";
import { usePublicProfile } from "@contexts/publicProfile";

import TextAreaForm from "@components/Form/TextAreaForm";
import TextFieldForm from "@components/Form/TextFieldForm";
import { ToggleSelector } from "@components/Form/ToggleSelector";
import InfoIcon from "@components/Icons/InfoIcon";
import FileInput, { FileData } from "@components/PublicProfile/FileInput";
import { PublicProfileSidebarPageProps } from "@components/PublicProfile/PublicProfileSidebar/Pages";
import PublicProfileSidebarSection from "@components/PublicProfile/PublicProfileSidebarSection";
import { RoundFileInput } from "@components/PublicProfile/RoundFileInput";

import LinksSection from "./LinksSection";
import { hoverClassNames, sharedProps } from "./RootPage";

const options = [
  {
    label: "Nothing",
    value: "nothing",
  },
  {
    label: "Image & text",
    value: "imageAndText",
  },
];

const PageHeaderSection: FC = () => {
  const { oid } = useAuth();
  const { data, updateData, updateCompanyData } = usePublicProfile();

  if (!data) return null;

  const { coachLocation, bio, title, companyName, companyDetails = {} } = data;

  const { showHero, heroImage, heroTitle, companyLogoMark } = companyDetails;

  return (
    <PublicProfileSidebarSection title="Page header">
      <PublicProfileSidebarSection title="Hero style" level={2}>
        <ToggleSelector
          className="bg-grey-900"
          onChange={(value) =>
            updateCompanyData({ showHero: value === "imageAndText" })
          }
          selected={showHero ? "imageAndText" : "nothing"}
          options={options}
        />
        {showHero && (
          <>
            <TextFieldForm
              {...sharedProps}
              name="heroTitle"
              onInputChange={(e) =>
                updateCompanyData({ heroTitle: e.target.value })
              }
              otherInputProps={{ value: heroTitle }}
              placeholder="Header text (optional)"
            />

            <FileInput
              data={heroImage ? ({ url: heroImage } as FileData) : null}
              uploadPath={`/users/${oid}/heroImages/`}
              onChange={(value) =>
                updateCompanyData({ heroImage: value?.url || null })
              }
              wrapperClassName="h-[160px]"
              imageClassName="object-cover"
              fullsizePreview
            />
            {!heroImage && (
              <div className="bg-blue-950 text-blue-300 rounded-lg p-2 flex gap-2 items-center text-sm">
                <InfoIcon className="flex-none" />
                <p>
                  We recommend using landscape and high resolution image for
                  best render.
                </p>
              </div>
            )}
          </>
        )}
      </PublicProfileSidebarSection>

      <div className="flex w-full gap-4">
        <div className="flex-none">
          <PublicProfileSidebarSection title="Picture" level={2}>
            <RoundFileInput
              aid={oid}
              url={companyLogoMark ?? undefined}
              onChange={(url) => updateCompanyData({ companyLogoMark: url })}
            />
          </PublicProfileSidebarSection>
        </div>
        <div className="w-full">
          <div className="text-grey-500 text-sm mb-2 ">Company name</div>
          <TextFieldForm
            {...sharedProps}
            inputClassName={classNames("w-full", hoverClassNames)}
            name="companyName"
            placeholder="Enter name"
            onInputChange={(e) => {
              updateData({ companyName: e.target.value });
            }}
            otherInputProps={{ value: companyName }}
          />
        </div>
      </div>

      <PublicProfileSidebarSection title="Company description" level={2}>
        <TextFieldForm
          {...sharedProps}
          name="title"
          placeholder="Enter description"
          onInputChange={(e) => {
            updateData({ title: e.target.value });
          }}
          otherInputProps={{ value: title }}
        />
      </PublicProfileSidebarSection>

      <PublicProfileSidebarSection title="Company location" level={2}>
        <TextFieldForm
          name="coachLocation"
          onInputChange={(e) => {
            updateData({ coachLocation: e.target.value });
          }}
          otherInputProps={{ value: coachLocation }}
          {...sharedProps}
        />
      </PublicProfileSidebarSection>
      <PublicProfileSidebarSection title="Company bio" level={2}>
        <TextAreaForm
          name="bio"
          containerClassName="!mb-0 -mt-1"
          onChange={(e) => {
            updateData({ bio: e.target.value });
          }}
          value={bio}
          maxRows={10}
          textClassName={hoverClassNames}
        />
      </PublicProfileSidebarSection>
    </PublicProfileSidebarSection>
  );
};

const AboutCompany: FC<PublicProfileSidebarPageProps> = () => {
  return (
    <>
      <PageHeaderSection />
      <LinksSection />
    </>
  );
};

export default AboutCompany;
