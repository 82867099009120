import { FC } from "react";
import classNames from "classnames";

import ConnectIcon from "@components/Icons/ConnectIcon";
import OfflineIcon from "@components/Icons/OfflineIcon";

export type DomainStatusType = keyof typeof statuses;

export const statuses = {
  Connected: { styles: { text: "text-green-500" } }, // no banner shown
  "Not connected": {
    title: "Connection in progress",
    descripton:
      "It might take up to 72 hours to finish the connection process.",
    icon: ConnectIcon,
    styles: {
      text: "text-green-100",
      bg: "bg-green-900",
    },
  },
  "Not connected properly": {
    title: "Domain is not connected properly",
    descripton:
      "Your DNS contains additional A, AAAA, or CNAME records. Press the edit button and make sure your DNS matches the description.",
    icon: OfflineIcon,
    styles: {
      text: "text-peach-400",
      bg: "bg-peach-950",
    },
  },
  Unreachable: {
    title: "Domain is unreachable",
    descripton:
      "Press the edit button and make sure your DNS matches the description.",
    icon: OfflineIcon,
    styles: {
      text: "text-peach-400",
      bg: "bg-peach-950",
    },
  },
};

interface DomainStatusProps {
  className?: string;
  status?: DomainStatusType;
}

const DomainStatus: FC<DomainStatusProps> = ({ className, status }) => {
  if (!status || status === "Connected") return null;

  const Icon = statuses[status].icon;

  return (
    <div
      className={classNames(
        className,
        statuses[status].styles.text,
        statuses[status].styles.bg,
        "rounded-md flex items-center gap-x-4 px-4 py-2 text-sm"
      )}
    >
      <Icon className="flex-none" />
      <div>
        <div className="font-medium">{statuses[status].title}</div>
        <div>{statuses[status].descripton}</div>
      </div>
    </div>
  );
};

export default DomainStatus;
