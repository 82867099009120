import { FC } from "react";
import { DragDropContextProps } from "react-beautiful-dnd";

import { usePublicProfile } from "@contexts/publicProfile";
import { displayNameFromContact } from "@lib/contacts";
import { AccountType } from "@lib/data/schemas/account";

import { AddButton } from "@components/Buttons";
import ClientAvatar from "@components/Client/ClientAvatar";
import FormDragAndDrop from "@components/FormDragAndDrop";
import Listbox, { ListboxOption } from "@components/Listbox";
import {
  PublicProfileSidebarPage,
  PublicProfileSidebarPageProps,
} from "@components/PublicProfile/PublicProfileSidebar/Pages";
import { PublicProfileSidebarHeader } from "@components/PublicProfile/PublicProfileSidebarHeader";
import PublicProfileSidebarSection from "@components/PublicProfile/PublicProfileSidebarSection";
import PublishButton from "@components/PublicProfile/PublishButton";

import {
  getHiddenItems,
  getItemsAfterDrag,
  getShownItems,
  updateHiddenStatus,
} from "../utils";

import PublicItemCard, { PublicItemType } from "./PublicItemCard";
import SectionTitle from "./SectionTitle";
import SidebarPageEmptyState from "./SidebarPageEmptyState";

const MembersPage: FC<PublicProfileSidebarPageProps> = ({
  setSelectedPage,
}) => {
  const { data, updateData, setPageResource } = usePublicProfile();
  const members = data?.members as AccountType[];

  const shown = getShownItems(members) as AccountType[];
  const hidden = getHiddenItems(members);

  const onDragEnd: DragDropContextProps["onDragEnd"] = (result) => {
    if (!result.destination) return; // dropped outside the list
    const newMembers = getItemsAfterDrag(
      result.source.index,
      result.destination.index,
      shown,
      members
    ) as AccountType[];
    newMembers && updateData({ members: newMembers });
  };

  const onUpdateHiddenStatus = (item: PublicItemType, newStatus: boolean) => {
    const newItems = updateHiddenStatus(
      item,
      newStatus,
      members as AccountType[]
    ) as AccountType[];
    updateData({ members: newItems });
  };

  const onClickEditMember = (member: AccountType) => {
    setPageResource({ ...member, isMemberResource: true });
    setSelectedPage && setSelectedPage(PublicProfileSidebarPage.root);
  };

  const onClickBack = () => {
    setPageResource(null);
    setSelectedPage(PublicProfileSidebarPage.root);
  };

  return (
    <>
      <PublicProfileSidebarHeader onClickBack={onClickBack}>
        <PublishButton isMemberPage={false} />
      </PublicProfileSidebarHeader>
      <PublicProfileSidebarSection title="Members">
        {shown?.length > 0 ? (
          <PublicProfileSidebarSection title="Section title" level={2}>
            <SectionTitle
              type="members"
              placeholder="Meet the team"
              isMemberPage={false}
            />
          </PublicProfileSidebarSection>
        ) : (
          <SidebarPageEmptyState className="!text-left">
            <div>
              <h2 className="font-medium text-black mb-2">Members list</h2>
              <p className="text-sm text-grey-500">
                When you add members, visitors can access their own public
                profile from your team profile.
              </p>
            </div>
          </SidebarPageEmptyState>
        )}
        <div>
          <FormDragAndDrop
            onDragEnd={onDragEnd}
            fields={shown || []}
            itemClassname="w-full mb-2 rounded-lg"
          >
            {(field, index) => {
              return (
                <PublicItemCard
                  key={`members-${index}`}
                  item={field as unknown as PublicItemType}
                  onUpdateHiddenStatus={onUpdateHiddenStatus}
                  type="member"
                  onClickEditMember={() => onClickEditMember(field)}
                />
              );
            }}
          </FormDragAndDrop>

          {hidden?.length > 0 && (
            <Listbox
              customListboxClassNames="!shadow-none"
              onChange={(member: PublicItemType) =>
                onUpdateHiddenStatus(member, false)
              }
              customAddButton={true}
              placeholder={
                <AddButton className="py-4 hover:bg-grey-900 bg-grey-950">
                  Add a member
                </AddButton>
              }
            >
              {hidden?.map((member, index) => {
                if (!member) return null;
                return (
                  <div key={index}>
                    <ListboxOption value={member}>
                      <div className="flex gap-4 items-center">
                        <ClientAvatar
                          className="!mx-0"
                          size="smaller"
                          client={member}
                        />
                        <div>
                          <h1>{displayNameFromContact(member)}</h1>
                          <h3 className="text-grey-500 text-sm">
                            {member.title}
                          </h3>
                        </div>
                      </div>
                    </ListboxOption>
                  </div>
                );
              })}
            </Listbox>
          )}
        </div>
      </PublicProfileSidebarSection>
    </>
  );
};

export default MembersPage;
