import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const TwitterIcon: FC<SVGIconProps> = ({ className }) => (
  <svg
    className={classNames("fill-current", className)}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M22 5.93723C21.2642 6.26611 20.4733 6.4876 19.6433 6.58744C20.4908 6.0765 21.1417 5.26687 21.4475 4.30204C20.655 4.77523 19.7767 5.11921 18.8417 5.30463C18.0942 4.50172 17.0267 4 15.8467 4C13.1975 4 11.2508 6.48844 11.8492 9.07169C8.44 8.89969 5.41667 7.25528 3.3925 4.75593C2.3175 6.61261 2.835 9.04148 4.66167 10.2714C3.99 10.2496 3.35667 10.0642 2.80417 9.75462C2.75917 11.6684 4.12167 13.4588 6.095 13.8573C5.5175 14.015 4.885 14.0519 4.24167 13.9278C4.76333 15.5688 6.27833 16.7627 8.075 16.7963C6.35 18.1579 4.17667 18.7662 2 18.5078C3.81583 19.6799 5.97333 20.3636 8.29 20.3636C15.9083 20.3636 20.2125 13.8858 19.9525 8.07581C20.7542 7.49271 21.45 6.76531 22 5.93723V5.93723Z" />
  </svg>
);

export default TwitterIcon;
