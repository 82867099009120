import { FC } from "react";
import classNames from "classnames";

interface IconWithDetailsProps {
  className?: string;
  id?: string;
  icon: JSX.Element;
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  rightElement?: JSX.Element;
  iconClassNames?: string;
  subtitleClassNames?: string;
  wrapperClassNames?: string;
  titleClassNames?: string;
}

const IconWithDetails: FC<IconWithDetailsProps> = ({
  className,
  id,
  icon,
  title,
  subtitle,
  rightElement,
  iconClassNames,
  subtitleClassNames,
  wrapperClassNames,
  titleClassNames,
}) => (
  <div
    className={classNames(
      "flex justify-between py-3 items-center gap-4 w-full",
      className
    )}
    id={id}
  >
    <div
      className={classNames(
        "flex w-full items-center gap-4",
        !rightElement && "pr-16",
        wrapperClassNames
      )}
    >
      <div
        className={classNames(
          "bg-foreground/7 text-foreground p-2 rounded-lg",
          iconClassNames
        )}
      >
        {icon}
      </div>
      <div className="w-full text-ellipsis overflow-hidden">
        <h3
          className={classNames("font-medium text-foreground", titleClassNames)}
        >
          {title}
        </h3>
        <h5
          className={classNames(
            "text-foreground/50 text-sm",
            subtitleClassNames
          )}
        >
          {subtitle}
        </h5>
      </div>
    </div>
    {rightElement}
  </div>
);

export default IconWithDetails;
