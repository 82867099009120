import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const ModernFontIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      viewBox="0 0 26 16"
      width="26"
      height="16"
    >
      <path d="M10.7234 11.664H4.11093L2.62583 15.7832H0.479492L6.50659 0H8.32773L14.3657 15.7832H12.2302L10.7234 11.664ZM4.73966 9.95122H10.1055L7.41716 2.56911L4.73966 9.95122Z" />
      <path d="M23.4172 15.7832C23.3015 15.5519 23.2076 15.14 23.1353 14.5474C22.2031 15.5158 21.0902 16 19.7966 16C18.6403 16 17.69 15.6748 16.9456 15.0244C16.2085 14.3668 15.8399 13.5357 15.8399 12.5312C15.8399 11.3098 16.3024 10.3631 17.2275 9.69106C18.1597 9.01174 19.4677 8.67209 21.1516 8.67209H23.1028V7.75068C23.1028 7.04968 22.8932 6.49323 22.4741 6.0813C22.0549 5.66215 21.437 5.45257 20.6204 5.45257C19.905 5.45257 19.3051 5.63324 18.821 5.99458C18.3368 6.35592 18.0947 6.79313 18.0947 7.30623H16.0784C16.0784 6.72087 16.2844 6.15718 16.6963 5.61518C17.1154 5.06594 17.6791 4.63234 18.3874 4.31436C19.1028 3.99639 19.8869 3.8374 20.7397 3.8374C22.0911 3.8374 23.1498 4.17706 23.9158 4.85637C24.6818 5.52846 25.0793 6.45709 25.1082 7.64228V13.0407C25.1082 14.1174 25.2455 14.9738 25.5201 15.6098V15.7832H23.4172ZM20.0892 14.2547C20.718 14.2547 21.3142 14.0921 21.8779 13.7669C22.4416 13.4417 22.8499 13.019 23.1028 12.4986V10.0921H21.531C19.0739 10.0921 17.8453 10.8112 17.8453 12.2493C17.8453 12.878 18.0549 13.3695 18.4741 13.7236C18.8932 14.0777 19.4316 14.2547 20.0892 14.2547Z" />
    </svg>
  );
};

export default ModernFontIcon;
