import React, { ReactNode } from "react";
import classNames from "classnames";

interface SelectButtonProps {
  isSelected: boolean;
  icon: ReactNode;
  name: string;
  onClick: () => void;
}

const SelectButton: React.FC<SelectButtonProps> = ({
  isSelected,
  icon,
  name,
  onClick,
}) => {
  return (
    <button
      className={classNames(
        "flex flex-col items-center justify-center pt-4 pb-2 bg-transparent hover:bg-white border border-grey-900 rounded-md",
        isSelected ? "bg-white text-black-ink" : "text-grey-500 grayscale"
      )}
      onClick={onClick}
    >
      <div>{icon}</div>
      <div className="text-sm leading-tight mt-2">{name}</div>
    </button>
  );
};

export default SelectButton;
