import { FC, useMemo } from "react";

import {
  PublicProfileSidebarPage,
  publicProfileSidebarPages,
} from "@components/PublicProfile/PublicProfileSidebar/Pages";
import { PublicProfileSidebarHeader } from "@components/PublicProfile/PublicProfileSidebarHeader";
import PublishButton from "@components/PublicProfile/PublishButton";

interface PublicProfileSidebarProps {
  isMemberPage?: boolean;
  setSelectedPage: (page: PublicProfileSidebarPage) => void;
  selectedPage: PublicProfileSidebarPage;
}

const PublicProfileSidebar: FC<PublicProfileSidebarProps> = ({
  isMemberPage = false,
  setSelectedPage,
  selectedPage,
}) => {
  const {
    component: Page,
    previousPage,
    customHeader,
  } = publicProfileSidebarPages[selectedPage];

  const onClickBack = useMemo(
    () =>
      previousPage
        ? () => setSelectedPage && setSelectedPage(previousPage)
        : undefined,
    [previousPage]
  );

  return (
    <div className="flex-none h-full -m-2 overflow-y-auto">
      <div className="w-[320px] m-2 flex flex-col gap-8 font-roboto">
        {!customHeader && (
          <PublicProfileSidebarHeader onClickBack={onClickBack}>
            <PublishButton isMemberPage={isMemberPage} />
          </PublicProfileSidebarHeader>
        )}
        <Page
          isMemberPage={isMemberPage}
          setSelectedPage={setSelectedPage}
          onClickBack={onClickBack}
        />
      </div>
    </div>
  );
};

export default PublicProfileSidebar;
