import { ExtendedAccount } from "@practice/sdk";

export const accountProfileFields = [
  "title",
  "bio",
  "avatarURL",
  "socialLinks",
  "website",
  "coachLocation",
  "sectionTitles",
  "slug",
  "testimonials",
  "firstName",
  "lastName",
];

export const getMemberRole = (member: ExtendedAccount) => {
  if (member.isOwner) return "Owner";
  if (member.accessType === "full") return "Admin";
  return "Partner";
};
