import { useMemo } from "react";
import { CustomFieldApi } from "@practice/sdk";
import { useDocument } from "swr-firebase";

import { compatDB as db } from "@lib/firebase-config";
import { CustomField, CustomFieldType, UserType } from "@lib/shared-types";
import { replacer } from "@lib/utils";
import formatField from "@lib/utils/formatCustomField";

import { useRequestIdGenerator } from "./use-request-id-generator";
import { useSDKApi } from "./use-sdk-api";

type FieldsObject = {
  [key: string]: string;
};

export default function useCustomFields({ coachId }: { coachId?: string }) {
  const generateRequestId = useRequestIdGenerator("use-custom-fields");
  const customFieldsApi = useSDKApi(CustomFieldApi);

  const { data: coach } = useDocument<UserType>(
    coachId ? `users/${coachId}` : null,
    {
      listen: true,
    }
  );

  const customFields = useMemo(() => {
    return coach?.customFields;
  }, [JSON.stringify(coach?.customFields ?? {}, replacer)]);

  const createCustomFields = async (fieldsArray: CustomField[] | undefined) => {
    const coachDocRef = await db.collection("users").doc(coachId);
    coachDocRef.update({ customFields: fieldsArray });
  };

  const customFieldExists = (field: string) =>
    customFields?.find((custom) => custom.field === field);

  const enterFieldAnswers = async (
    fieldsObject: FieldsObject,
    clientId: string
  ) => {
    const { clientDocRef } = await getClientsAnswers(clientId);
    const formattedFields: { field: string; value: string }[] = [];
    for (const field in fieldsObject) {
      const f = formatField(field, false);
      formattedFields.push({ field: f, value: fieldsObject[field] });
    }
    await clientDocRef.update({ customFields: formattedFields });
  };

  const getClientsAnswers = async (clientId: string) => {
    const clientDocRef = await db
      .collection("users")
      .doc(coachId)
      .collection("clients")
      .doc(clientId);
    const clientDoc = await clientDocRef.get();
    return {
      fieldAnswers: clientDoc.data()?.customFields,
      clientDocRef,
    };
  };

  const getFieldType = (field: string): CustomFieldType | undefined => {
    let type: CustomFieldType | undefined;
    customFields?.map((customField) => {
      if (customField.field === field) {
        type = customField.type;
      }
    });
    return type;
  };

  const deleteFieldFromClients = async (field: string) => {
    if (!coachId) return;

    await customFieldsApi.deleteCustomField({
      organizationId: coachId,
      customFieldId: field,
      xRequestId: generateRequestId(),
    });
  };

  return {
    customFields,
    createCustomFields,
    enterFieldAnswers,
    customFieldExists,
    getFieldType,
    deleteFieldFromClients,
  };
}
