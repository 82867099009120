import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const RoundedFontIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      viewBox="0 0 26 16"
      width="26"
      height="16"
    >
      <path d="M0.696798 15.7746C0.57661 15.7746 0.471446 15.7296 0.381305 15.6394C0.291164 15.5493 0.246094 15.4441 0.246094 15.3239C0.246094 15.2488 0.253606 15.1662 0.268629 15.0761L5.85736 0.495775C5.91746 0.34554 6.00008 0.225353 6.10525 0.135212C6.21041 0.0450707 6.36816 0 6.57849 0H8.11088C8.30619 0 8.45642 0.0450707 8.56159 0.135212C8.68178 0.225353 8.77192 0.34554 8.83201 0.495775L14.3982 15.0761C14.4283 15.1662 14.4433 15.2488 14.4433 15.3239C14.4433 15.4441 14.3982 15.5493 14.3081 15.6394C14.2179 15.7296 14.1128 15.7746 13.9926 15.7746H12.8433C12.663 15.7746 12.5278 15.7296 12.4376 15.6394C12.3625 15.5493 12.3099 15.4667 12.2799 15.3915L11.0405 12.1916H3.64891L2.40947 15.3915C2.39445 15.4667 2.34187 15.5493 2.25173 15.6394C2.16159 15.7296 2.02638 15.7746 1.84609 15.7746H0.696798ZM4.30243 10.2535H10.3869L7.34469 2.25352L4.30243 10.2535Z" />
      <path d="M19.8274 16C19.0763 16 18.3927 15.8498 17.7767 15.5493C17.1608 15.2488 16.665 14.8432 16.2894 14.3324C15.9138 13.8216 15.726 13.2432 15.726 12.5972C15.726 11.5606 16.1467 10.7343 16.988 10.1183C17.8293 9.50235 18.926 9.09671 20.2781 8.90141L23.6359 8.42817V7.77465C23.6359 7.05352 23.4256 6.49014 23.0049 6.08451C22.5993 5.67887 21.9307 5.47606 20.9993 5.47606C20.3232 5.47606 19.7749 5.61127 19.3542 5.88169C18.9486 6.15211 18.6631 6.49765 18.4979 6.91831C18.4077 7.14366 18.25 7.25634 18.0246 7.25634H17.0105C16.8453 7.25634 16.7176 7.21127 16.6274 7.12113C16.5523 7.01596 16.5148 6.89577 16.5148 6.76056C16.5148 6.53521 16.5974 6.25728 16.7626 5.92676C16.9429 5.59624 17.2133 5.27324 17.5739 4.95775C17.9345 4.64225 18.3927 4.37934 18.9486 4.16901C19.5194 3.94366 20.2105 3.83099 21.0218 3.83099C21.9232 3.83099 22.6819 3.95117 23.2979 4.19155C23.9138 4.4169 24.3946 4.72488 24.7401 5.11549C25.1007 5.5061 25.3561 5.9493 25.5063 6.44507C25.6716 6.94085 25.7542 7.44413 25.7542 7.95493V15.2563C25.7542 15.4066 25.7016 15.5343 25.5964 15.6394C25.5063 15.7296 25.3861 15.7746 25.2359 15.7746H24.1993C24.034 15.7746 23.9063 15.7296 23.8162 15.6394C23.726 15.5343 23.681 15.4066 23.681 15.2563V14.2873C23.4856 14.5577 23.2227 14.8282 22.8922 15.0986C22.5617 15.354 22.1486 15.5718 21.6528 15.7521C21.157 15.9174 20.5486 16 19.8274 16ZM20.3007 14.3099C20.9166 14.3099 21.48 14.1822 21.9908 13.9268C22.5016 13.6563 22.8997 13.2432 23.1852 12.6873C23.4856 12.1315 23.6359 11.4329 23.6359 10.5915V9.96056L21.0218 10.3437C19.9551 10.4939 19.1514 10.7493 18.6105 11.1099C18.0697 11.4554 17.7993 11.8986 17.7993 12.4394C17.7993 12.8601 17.9195 13.2131 18.1598 13.4986C18.4152 13.769 18.7307 13.9718 19.1063 14.107C19.4969 14.2423 19.895 14.3099 20.3007 14.3099Z" />
    </svg>
  );
};

export default RoundedFontIcon;
