import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const PictureIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 12C16.6569 12 18 10.6569 18 9C18 7.34315 16.6569 6 15 6C13.3431 6 12 7.34315 12 9C12 10.6569 13.3431 12 15 12ZM14 9C14 8.44772 14.4477 8 15 8C15.5523 8 16 8.44772 16 9C16 9.55228 15.5523 10 15 10C14.4477 10 14 9.55228 14 9Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2C2.44772 2 2 2.44772 2 3V21C2 21.5523 2.44772 22 3 22H21C21.5523 22 22 21.5523 22 21V3C22 2.44772 21.5523 2 21 2H3ZM4 4V12.3145C5.42671 11.6547 6.65102 11.4583 7.78831 11.6282C9.19159 11.8377 10.3134 12.5985 11.2977 13.266L11.4353 13.3592C12.4967 14.0772 13.518 14.7439 14.8985 15.0651C16.1401 15.354 17.7513 15.378 20 14.7547V4H4ZM4 20V14.5661C5.6184 13.6383 6.68625 13.4857 7.49288 13.6062C8.43006 13.7462 9.20701 14.2665 10.3147 15.0158C11.3782 15.7353 12.6694 16.5999 14.4452 17.013C15.955 17.3643 17.7467 17.3769 20 16.8219V20H4Z"
      />
    </svg>
  );
};

export default PictureIcon;
