import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { updateOrganizationAccount } from "api-services/definitions/accounts";
import { useApi } from "api-services/endpoints";
import { isEqual, omit, pick } from "lodash";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";
import { useTheme } from "@contexts/theme";
import useWarnIfUnsavedChanges from "@hooks/use-warn-if-unsaved-changes";
import { AccountType } from "@lib/data/schemas/account";
import { getCurrentURIFromServer } from "@lib/utils";

import {
  MemberPublicProfileContextType,
  PublicDataType,
  PublicProfileProviderProps,
} from "./types";

const MemberPublicProfileContext =
  createContext<MemberPublicProfileContextType>({
    data: null,
    updateData: async () => {},
    hasChangedSlug: false,
    hasUnsavedChanges: false,
    publishChanges: async () => {},
  });

export const useMemberPublicProfile = (): MemberPublicProfileContextType => {
  return useContext(MemberPublicProfileContext);
};

const pickedFields = [
  "bio",
  "title",
  "firstName",
  "lastName",
  "avatarURL",
  "coachLocation",
  "slug",
  "website",
  "socialLinks",
  "sectionTitles",
  "memberSchedulers",
  "memberPackages",
  "publicOrder",
  "secret",
];

export const getMemberPayload = (
  member: AccountType,
  original: AccountType
) => {
  const originalMember = pick(original, pickedFields);
  const newMember = pick(member, pickedFields);
  if (!isEqual(originalMember, newMember)) {
    return newMember;
  }
  return {};
};

const MemberPublicProfileProvider: FC<PublicProfileProviderProps> = ({
  children,
}) => {
  const { setTheme } = useTheme();
  const { oid } = useAuth();
  const { apiCall: updateMember } = useApi(updateOrganizationAccount);
  const router = useRouter();

  const [data, setData] = useState<PublicDataType | null>(null);
  const [initialData, setInitialData] = useState<PublicDataType | null>(null);

  const hasChangedSlug = data?.slug !== initialData?.slug;

  const hasUnsavedChanges = initialData
    ? !isEqual(omit(data), initialData)
    : false;

  useWarnIfUnsavedChanges({
    isDirty: hasUnsavedChanges,
    message: "You have unpublished changes. Are you sure you want to leave?",
  });

  const updateData = useCallback(
    async (updatedData: Partial<PublicDataType>) => {
      setData((data) => ({ ...data!, ...updatedData }));
      const themeData = pick(updatedData, ["color", "isDynamicTheme", "font"]);
      if (themeData) setTheme((theme) => ({ ...theme, ...themeData }));
    },
    [setTheme]
  );

  const publishChanges = useCallback(async () => {
    if (!data) return;

    const { schedulers, packages, members, ...user } = data;

    const payload = pick(user, pickedFields);

    await updateMember({ userId: oid!, accountId: user.id }, payload, {});

    const updatedSlug = user?.slug !== initialData?.slug;

    setInitialData(data);

    if (updatedSlug) {
      router.push(
        `${getCurrentURIFromServer()}/me/${user.orgSlug}/${user.slug}`
      );
    }
  }, [data, oid]);

  useEffect(() => {
    if (initialData) return;

    setInitialData(data);
  }, [data, initialData]);

  return (
    <MemberPublicProfileContext.Provider
      value={{
        data,
        updateData,
        hasChangedSlug,
        hasUnsavedChanges,
        publishChanges,
      }}
    >
      {children}
    </MemberPublicProfileContext.Provider>
  );
};

export { MemberPublicProfileContext, MemberPublicProfileProvider };
