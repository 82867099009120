import { FC } from "react";
import classNames from "classnames";

import { PackageInstancePaymentType } from "@lib/data/schemas/package-instance";

import { PublicItemType } from "./PublicProfileSidebar/Pages/PublicItemCard";
import PublicBookingPackageSection from "./PublicBookingPackageSection";
import PublicBookingSchedulerSection from "./PublicBookingSchedulerSection";

export type ItemType = PublicItemType & {
  productDescription: string;
  payment: PackageInstancePaymentType;
};

interface PublicBookingSectionProps {
  items: ItemType[];
  title: string;
  userSlug?: string;
  type: string;
  editMode?: boolean;
}

const PublicBookingSection: FC<PublicBookingSectionProps> = ({
  items,
  title,
  userSlug,
  type,
  editMode,
}) => {
  const isScheduler = type === "scheduler";

  const renderSection = (item: ItemType) => {
    if (isScheduler) {
      return (
        <PublicBookingSchedulerSection
          item={item}
          userSlug={userSlug}
          editMode={editMode}
        />
      );
    } else {
      return (
        <PublicBookingPackageSection
          item={item}
          userSlug={userSlug}
          editMode={editMode}
        />
      );
    }
  };

  return (
    <div id={`${type}-section`}>
      <div
        className={classNames(
          editMode ? "lg:grid lg:grid-cols-4" : "md:grid md:grid-cols-4"
        )}
      >
        <h3 className={"pb-4 text-2xl font-medium text-foreground col-span-4"}>
          {title}
        </h3>
      </div>
      <div
        className={classNames(
          "grid col-span-4 px-0  grid-cols-1 w-full gap-4",
          editMode
            ? "lg:col-span-4 lg:grid-cols-2 lg:px-0"
            : "md:col-span-4 md:grid-cols-2 md:px-0 sm:px-16"
        )}
      >
        {items.map(renderSection)}
      </div>
    </div>
  );
};

export default PublicBookingSection;
