import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const LocationIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2916 16C18.9525 14.5341 20 12.3894 20 10C20 5.58172 16.4183 2 12 2C7.58172 2 4 5.58172 4 10C4 12.3894 5.04751 14.5341 6.70835 16C7.81464 16.9764 9.6131 19.0861 10.8014 20.5248C11.4188 21.2723 12.577 21.2694 13.1923 20.52C14.3518 19.1079 16.107 17.0456 17.2916 16ZM18 10C18 11.7918 17.2171 13.3982 15.9682 14.5005C14.7699 15.5581 13.1389 17.45 11.994 18.83C10.8263 17.429 9.16103 15.4972 8.03182 14.5005C6.78287 13.3982 6 11.7918 6 10C6 6.68629 8.68629 4 12 4C15.3137 4 18 6.68629 18 10Z"
      />
    </svg>
  );
};

export default LocationIcon;
