import { z } from "zod";

import { getSchemaDefinition } from "../common";

const QuerySchema = z.object({
  domain: z.string(),
});

export const getVerifyDomain = getSchemaDefinition(
  "/api/v1/domains/verify",
  "get",
  {
    query: QuerySchema,
  },
  {
    description:
      "Check to see if a domain has the correct A/CNAME records and return what we do find.",
    tags: ["domains"],
  },
  z.object({
    verification: z.string(),
    records: z.array(
      z
        .object({
          type: z.string(),
          value: z.string(),
        })
        .optional()
    ),
  })
);
