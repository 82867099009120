import { FC } from "react";
import classNames from "classnames";
import Link from "next/link";

import { AccountType } from "@lib/data/schemas/account";

import TypeIllustration from "@components/TypeIllustration";

import AccountDetail from "./AccountDetail";

interface PublicBookingItemProps {
  link: string;
  icon: string;
  subtitle: string;
  description: string;
  title: string;
  currencyString?: string;
  accounts?: Partial<AccountType>[];
  editMode?: boolean;
}

const PublicBookingItem: FC<PublicBookingItemProps> = ({
  link,
  icon,
  currencyString,
  subtitle,
  description,
  title,
  accounts,
  editMode,
}) => (
  <div>
    <Link href={link} target="_blank" rel="noreferrer">
      <div className={classNames("p-4", editMode ? "xl:p-8" : "sm:p-8")}>
        <div
          className={classNames(
            "flex justify-between w-full items-center pb-4"
          )}
        >
          {icon && <TypeIllustration slug={icon} width={32} height={32} />}
          {currencyString && (
            <div className="px-2 py-1 rounded-md flex justify-center items-center text-background bg-accent">
              <div>{currencyString}</div>
            </div>
          )}
        </div>
        <div className="flex flex-col justify-center text-foreground">
          <div className="text-2xl truncate font-medium">{title}</div>
          <div className="flex items-center text-foreground/75 gap-2">
            {accounts && (
              <AccountDetail
                accountClassNames="text-sm font-medium"
                accounts={accounts}
              />
            )}
            <p>{subtitle}</p>
          </div>
          <div
            className={classNames(
              "pt-3 truncate-3-lines leading-snug text-foreground/80",
              editMode ? "lg:pt-4" : "sm:pt-4"
            )}
          >
            {description}
          </div>
        </div>
      </div>
    </Link>
  </div>
);

export default PublicBookingItem;
