import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const StarIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.6549 3.46591C11.2051 2.35104 12.7949 2.35105 13.3451 3.46591L15.3091 7.4454L19.7007 8.08354C20.931 8.26232 21.4223 9.77428 20.532 10.6421L17.3542 13.7397L18.1044 18.1136C18.3146 19.3389 17.0284 20.2734 15.928 19.6948L12 17.6298L8.07198 19.6948C6.97154 20.2734 5.68539 19.3389 5.89556 18.1136L6.64574 13.7397L3.46793 10.6421C2.57765 9.77428 3.06892 8.26232 4.29925 8.08354L8.69087 7.4454L10.6549 3.46591ZM12 5.25954L10.368 8.56625C10.1495 9.00897 9.72717 9.31582 9.2386 9.38682L5.58943 9.91707L8.23 12.491C8.58353 12.8356 8.74485 13.3321 8.66139 13.8187L8.03804 17.4531L11.302 15.7372C11.7389 15.5074 12.261 15.5074 12.698 15.7372L15.9619 17.4531L15.3386 13.8187C15.2551 13.3321 15.4164 12.8356 15.77 12.491L18.4105 9.91707L14.7613 9.38682C14.2728 9.31582 13.8504 9.00897 13.6319 8.56625L12 5.25954Z" />
    </svg>
  );
};

export default StarIcon;
