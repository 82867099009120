import { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import classNames from "classnames";

import { useAuth } from "@contexts/auth";
import useDomainStatus from "@hooks/use-domain-status";
import useHasFeature from "@hooks/use-has-feature";
import { useProfileData } from "@hooks/useProfileData";
import analytics from "@lib/analytics";
import { FeatureNames } from "@lib/constants/featureNames";
import { getCurrentURIFromServer } from "@lib/utils";

import SmallBanner from "@components/Banner/SmallBanner";
import { Button } from "@components/Button";
import DomainStatus, {
  DomainStatusType,
  statuses,
} from "@components/Domains/DomainStatus";
import UserSlug from "@components/Domains/UserSlugForm";
import { DropdownItem } from "@components/Dropdowns/SecondaryDropdown";
import FilledCheckbox from "@components/Form/FilledCheckbox";
import IconAction from "@components/IconAction";
import AlertIcon from "@components/Icons/AlertIcon";
import PencilIcon from "@components/Icons/PencilIcon";
import ProBadgeIcon from "@components/Icons/ProBadgeIcon";
import TrashIcon from "@components/Icons/TrashIcon";
import SubscriptionPlanModal from "@components/Plans/SubscriptionPlanModal";
import {
  PublicProfileSidebarPage,
  PublicProfileSidebarPageProps,
} from "@components/PublicProfile/PublicProfileSidebar/Pages";
import PublicProfileSidebarSection from "@components/PublicProfile/PublicProfileSidebarSection";

interface FormData {
  slug: string;
}

interface DomainsSectionProps {
  setSelectedPage: (page: PublicProfileSidebarPage) => void;
  isMemberPage: boolean;
}

const DomainsSection: FC<DomainsSectionProps> = ({
  setSelectedPage,
  isMemberPage,
}) => {
  const { oid } = useAuth();
  const { data, hasChangedSlug, updateData, isNestedMember } =
    useProfileData(isMemberPage);

  const domains = data?.domains;
  const status = useDomainStatus(domains?.domainName ?? "");
  const methods = useForm<FormData>();
  const {
    formState: { errors },
  } = methods;

  const [showChangePlanModal, setShowChangePlanModal] = useState(false);
  const [editSlug, setEditSlug] = useState(false);
  const [hasCustomDomains, isHasCustomDomainsLoading] = useHasFeature(
    oid,
    FeatureNames.customDomains
  );

  const renderSlugWarning = (
    <SmallBanner
      className=""
      items={[
        {
          Icon: AlertIcon,
          text: "Changing your URL will immediately break all links shared with your clients.",
        },
      ]}
      variant="alert"
    />
  );

  const renderSlugField = () => (
    <FormProvider {...methods}>
      <UserSlug isMemberPage={isMemberPage} />

      {hasChangedSlug && renderSlugWarning}
    </FormProvider>
  );

  if (isMemberPage || isNestedMember) return renderSlugField();

  const renderConnectDomain = () => (
    <div className="flex flex-col gap-3 p-3 items-start bg-white border border-grey-900 rounded-lg">
      <div className="flex-1 flex flex-col gap-0.5">
        <p className="flex gap-1.5 items-center text-sm leading-tight">
          Custom domain
          <ProBadgeIcon className="h-4 w-auto" />
        </p>
        <p className="text-xs text-grey-500">
          Enhance your brand with a custom domain
        </p>
      </div>
      <Button
        variant="primary"
        small
        className="w-full"
        onClick={() => {
          if (hasCustomDomains) {
            analytics.track("coach_clicks_connect_domain_empty_state");
            setSelectedPage(PublicProfileSidebarPage.connectDomainStep1);
          } else {
            setShowChangePlanModal(true);
          }
        }}
      >
        Connect domain
      </Button>
      {showChangePlanModal && (
        <SubscriptionPlanModal
          show
          toggleShow={setShowChangePlanModal}
          onClose={() => setShowChangePlanModal(false)}
        />
      )}
    </div>
  );

  const renderContent = () => {
    if (hasCustomDomains && domains?.domainName) {
      if (editSlug) {
        return (
          <PublicProfileSidebarSection title="Edit Practice URL" level={2}>
            {renderSlugField()}
            <Button
              variant="primary"
              small
              onClick={() => {
                setEditSlug(false);
              }}
              disabled={!!errors.slug}
              className="disabled:bg-grey-900"
            >
              Save
            </Button>
          </PublicProfileSidebarSection>
        );
      }

      return (
        <PublicProfileSidebarSection
          title="Customize the domain used when your clients visit your pages:"
          level={2}
        >
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <DomainOption
                label={`${getCurrentURIFromServer()}/me/${data?.slug}`}
                description="Hosted by Practice"
                isChecked={!domains.useCustomDomain}
                onChange={() =>
                  updateData({
                    domains: { ...data?.domains, useCustomDomain: false },
                  })
                }
                dropdownItems={[
                  {
                    type: "button",
                    Icon: PencilIcon,
                    title: "Edit",
                    variant: "primary",
                    onClick: () => setEditSlug(true),
                  },
                ]}
              />

              <DomainOption
                label={domains.domainName}
                description="Your own domain"
                isChecked={domains.useCustomDomain ?? false}
                onChange={() =>
                  updateData({
                    domains: { ...data?.domains, useCustomDomain: true },
                  })
                }
                disabled={editSlug}
                status={status}
                dropdownItems={[
                  {
                    type: "button",
                    Icon: PencilIcon,
                    title: "Edit",
                    variant: "primary",
                    onClick: () => {
                      setSelectedPage(
                        PublicProfileSidebarPage.connectDomainStep1
                      );
                      analytics.track("coach_start_edit_custom_domain");
                    },
                  },
                  {
                    type: "button",
                    Icon: TrashIcon,
                    title: "Delete",
                    variant: "delete",
                    onClick: () => {
                      updateData({
                        domains: null,
                        pendingDomains: null,
                      });
                      analytics.track("coach_removed_custom_domain");
                    },
                  },
                ]}
              />
            </div>

            {domains.useCustomDomain && (
              <DomainStatus
                className={editSlug ? "opacity-50" : ""}
                status={status}
              />
            )}
          </div>
        </PublicProfileSidebarSection>
      );
    }

    return (
      <PublicProfileSidebarSection title="Practice URL" level={2}>
        {renderSlugField()}
        {renderConnectDomain()}
      </PublicProfileSidebarSection>
    );
  };

  if (isHasCustomDomainsLoading) return null;

  return (
    <PublicProfileSidebarSection title="Domains">
      {renderContent()}
    </PublicProfileSidebarSection>
  );
};

const DomainsAndPresencePage: FC<PublicProfileSidebarPageProps> = ({
  setSelectedPage,
  isMemberPage = false,
}) => (
  <>
    <DomainsSection
      setSelectedPage={setSelectedPage}
      isMemberPage={isMemberPage}
    />
  </>
);

export default DomainsAndPresencePage;

interface DomainOptionProps {
  label: string;
  description: string;
  isChecked: boolean;
  onChange: () => void;
  dropdownItems?: DropdownItem[];
  disabled?: boolean;
  status?: DomainStatusType;
}

const DomainOption: FC<DomainOptionProps> = ({
  label,
  description,
  isChecked,
  onChange,
  dropdownItems,
  disabled,
  status,
}) => (
  <div
    className={classNames(
      "flex gap-3 py-3.5 px-3 border rounded-lg items-start",
      isChecked ? "bg-action-900 border-action-800" : "border-grey-900",
      disabled && "opacity-50"
    )}
  >
    <FilledCheckbox
      className="flex-none"
      disabled={disabled}
      variant="round"
      value={isChecked}
      onChange={onChange}
    />

    <div className="flex flex-col gap-1 py-0.5 truncate">
      <p className="text-sm font-medium leading-tight truncate">{label}</p>
      <p className="text-xs leading-tight text-grey-500">
        {status && (
          <>
            <span
              className={classNames(
                statuses[status].styles.text,
                "font-medium"
              )}
            >
              {status}
            </span>
            <span className="text-grey-500 mx-1">•</span>
          </>
        )}
        {description}
      </p>
    </div>

    {!disabled && dropdownItems && (
      <IconAction
        className="ml-auto relative text-grey-500"
        type="dropdown"
        showOnHover={false}
        size="small"
        dropdownItems={dropdownItems}
      />
    )}
  </div>
);
