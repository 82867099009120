import { useCallback, useEffect, useRef } from "react";
import { Transition } from "@headlessui/react";
import classNames from "classnames";

const borderRadiusClasses = {
  medium: "rounded-lg",
  big: "rounded-3xl",
};

const shadowClasses = {
  medium: "shadow-xl",
  big: "shadow-equal-20",
};

const maxWidthClasses = {
  medium: "max-w-lg",
  big: "max-w-sm",
};

export const Dialog = ({
  show,
  setShow = () => {},
  children,
  className,
  borderRadius = "big",
  shadowSize = "big",
  maxWidth = "big",
  width = "full",
  noPadding = false,
}) => {
  const ref = useRef();
  const handleKeyDown = useCallback(
    (e) => (e.key === "Esc" || e.key === "Escape") && setShow(false),
    [setShow]
  );

  useEffect(() => {
    const handleMouseDown = (e) => setShow(!!ref.current?.contains(e.target));
    document.addEventListener("mousedown", handleMouseDown);

    return () => document.removeEventListener("mousedown", handleMouseDown);
  }, [setShow]);

  return (
    <div ref={ref}>
      <Transition
        show={show}
        enter="transform transition-all ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="transform transition-allease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        className={classNames(
          "absolute z-30 bg-white transform transition-all",
          borderRadiusClasses[borderRadius],
          shadowClasses[shadowSize],
          maxWidthClasses[maxWidth],
          `w-${width}`,
          noPadding ? "p-0" : "p-4 sm:p-6",
          className
        )}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
        onKeyDown={handleKeyDown}
      >
        {children}
      </Transition>
    </div>
  );
};
