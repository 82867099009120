import React, { FC } from "react";
import Masonry from "react-masonry-css";

import ClientAvatar from "@components/Client/ClientAvatar";
import { TestimonialsProps } from "@components/PublicProfile/types";

const Testimonials: FC<TestimonialsProps> = ({ testimonials, editMode }) => {
  if (!testimonials || testimonials?.length === 0) return null;
  const signature = (testimonial: { date: string; name: string }) => (
    <div className="flex items-center pt-4n text-accent">
      <ClientAvatar
        className="ml-0 mr-4 !text-accent !bg-foreground/20"
        client={{ firstName: testimonial.name }}
      />
      {`${testimonial.name}`}
    </div>
  );

  const breakpointColumnsObj = { default: 2, [editMode ? 1000 : 500]: 1 };

  return (
    <div id="testimonial-section" className="pt-10">
      <div className="pb-10">
        <p className="text-3xl font-thin mb-2 text-foreground/75">{`"${testimonials[0].text}"`}</p>
        {signature(testimonials[0])}
      </div>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="flex gap-4"
        columnClassName="bg-clip-padding"
      >
        {testimonials.slice(1).map((testimonial, index) => (
          <div
            key={index}
            className="rounded-xl p-4 mb-4 bg-foreground/7 text-foreground"
          >
            <p className="mb-4 whitespace-pre-wrap">{testimonial.text}</p>
            {signature(testimonial)}
          </div>
        ))}
      </Masonry>
    </div>
  );
};

export default Testimonials;
