import { FC } from "react";
import classNames from "classnames";
import { clone } from "lodash";

import { useProfileData } from "@hooks/useProfileData";
import { TestimonialType } from "@lib/data/schemas/user";

import TextAreaForm from "@components/Form/TextAreaForm";
import TextFieldForm from "@components/Form/TextFieldForm";
import TrashIcon from "@components/Icons/TrashIcon";
import {
  PublicProfileSidebarPage,
  PublicProfileSidebarPageProps,
} from "@components/PublicProfile/PublicProfileSidebar/Pages";
import { PublicProfileSidebarHeader } from "@components/PublicProfile/PublicProfileSidebarHeader";
import PublicProfileSidebarSection from "@components/PublicProfile/PublicProfileSidebarSection";
import { Tooltip } from "@components/Tooltips/Tooltip";

import { hoverClassNames } from "./RootPage";

const EditTestimonialPage: FC<PublicProfileSidebarPageProps> = ({
  setSelectedPage,
}) => {
  const { data, updateData, pageResource } = useProfileData(false);
  const current = pageResource as unknown as TestimonialType & {
    index: number;
    icon: JSX.Element;
  };
  const currentIndex = current?.index;
  const testimonials = data?.testimonials || [];
  const testimonial = testimonials[currentIndex];
  const isLocked = !!testimonial?.clientId;
  const temp = clone(testimonials);

  const onUpdate = (payload: { name?: string; text?: string }) => {
    temp[currentIndex] = {
      ...testimonial,
      ...payload,
    };
    updateData({ testimonials: temp });
  };

  const goBack = () => setSelectedPage(PublicProfileSidebarPage.testimonials);

  const onDelete = () => {
    temp?.splice(currentIndex, 1);
    updateData({ testimonials: temp });
    goBack();
  };

  const header = (
    <PublicProfileSidebarHeader onClickBack={goBack}>
      <Tooltip
        trigger={
          <div
            onClick={onDelete}
            className="bg-peach-950 text-peach-500 p-1 rounded-lg hover:cursor-pointer"
          >
            <TrashIcon />
          </div>
        }
        placement="bottom"
        contentClassName="!py-1 !px-3"
      >
        Delete this testimonial
      </Tooltip>
    </PublicProfileSidebarHeader>
  );

  const isNew = !testimonial?.text && !testimonial?.name;

  return (
    <>
      {header}
      <PublicProfileSidebarSection
        title={`${isNew ? "New" : "Edit"} testimonial`}
      >
        <PublicProfileSidebarSection title="Client identity" level={2}>
          <div className="flex items-center gap-2">
            <div>{current?.icon}</div>
            <TextFieldForm
              name="name"
              containerClassName={classNames("!mb-0 -mt-1 w-full")}
              placeholder="Name"
              onInputChange={(e) => onUpdate({ name: e.target.value })}
              otherInputProps={{ value: testimonial?.name }}
              disabled={isLocked}
              inputClassName={classNames(
                hoverClassNames,
                isLocked && "text-grey-500/70 !bg-grey-800/30"
              )}
            />
          </div>
        </PublicProfileSidebarSection>
        <PublicProfileSidebarSection title="Testimonial" level={2}>
          <TextAreaForm
            name="testimonial"
            containerClassName="!mb-0 -mt-1 h-full"
            onChange={(e) => onUpdate({ text: e.target.value })}
            value={testimonial?.text}
            disabled={isLocked}
            textClassName={classNames(
              hoverClassNames,
              "h-full",
              isLocked &&
                "text-grey-500/70 !bg-grey-800/30 hover:cursor-not-allowed"
            )}
          />
        </PublicProfileSidebarSection>
      </PublicProfileSidebarSection>
    </>
  );
};

export default EditTestimonialPage;
