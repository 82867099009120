import { FC, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { usePublicProfile } from "@contexts/publicProfile";
import analytics from "@lib/analytics";
import { sanitizeDomainName, sanitizeSubdomainName } from "@lib/utils/domains";

import { Button } from "@components/Button";
import TextFieldForm from "@components/Form/TextFieldForm";
import { Option } from "@components/PublicProfile/Option";
import {
  PublicProfileSidebarPage,
  PublicProfileSidebarPageProps,
} from "@components/PublicProfile/PublicProfileSidebar/Pages";
import { PublicProfileSidebarHeader } from "@components/PublicProfile/PublicProfileSidebarHeader";
import PublicProfileSidebarSection from "@components/PublicProfile/PublicProfileSidebarSection";

import { hoverClassNames } from "./RootPage";

interface FormData {
  rootDomainName: string;
  subdomainName: string;
}

const ConnectDomainStep1Page: FC<PublicProfileSidebarPageProps> = ({
  setSelectedPage,
  onClickBack,
}) => {
  const { data, updateData } = usePublicProfile();

  const defaultValues = data?.pendingDomains ?? data?.domains;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues,
  });

  const rootDomainName = sanitizeDomainName(watch("rootDomainName"));
  const subdomainName = sanitizeSubdomainName(watch("subdomainName"));
  const [showSubdomainName, setShowSubdomainName] = useState(
    !!defaultValues?.subdomainName
  );

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    const rootDomainName = sanitizeDomainName(formData.rootDomainName);
    const subdomainName = sanitizeSubdomainName(formData.subdomainName);
    const domainName = subdomainName
      ? `${subdomainName}.${rootDomainName}`
      : rootDomainName;

    updateData({
      pendingDomains: {
        rootDomainName,
        subdomainName,
        domainName,
        useCustomDomain: true,
      },
    });

    setSelectedPage(PublicProfileSidebarPage.connectDomainStep2);
  };

  useEffect(() => {
    analytics.track("coach_starts_domain_connect");
  }, []);

  return (
    <>
      <PublicProfileSidebarHeader
        onClickBack={() => {
          updateData({ pendingDomains: null });
          onClickBack?.();
        }}
      >
        <Button type="submit" form="domains-form" variant="primary" smaller>
          Next step
        </Button>
      </PublicProfileSidebarHeader>
      <PublicProfileSidebarSection title="Connect your domain (1/2)">
        <div className="flex flex-col gap-2">
          <Option
            selected={!showSubdomainName}
            onClick={() => {
              setShowSubdomainName(false);
              setValue("subdomainName", "");
              clearErrors("subdomainName");
            }}
            label="Connect domain to Practice"
            text="I want my practice URL to look like: example.com. If my existing
          domain already points elsewhere, it will now point to my Practice
          site instead."
          />
          <Option
            selected={showSubdomainName}
            onClick={() => setShowSubdomainName(true)}
            label="Create subdomain for Practice"
            text="I want my practice URL to look like: coaching.example.com. This
          won’t affect where my existing domain points to (such as to
            Squarespace, Wix, etc.)"
          />
        </div>
      </PublicProfileSidebarSection>
      <PublicProfileSidebarSection
        title={`Let’s connect your ${
          showSubdomainName ? "subdomain" : "domain"
        }`}
      >
        <form
          className="flex flex-col gap-2"
          id="domains-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextFieldForm
            containerClassName="!mb-0"
            label="Domain name"
            name="rootDomainName"
            placeholder="mywebsite.com"
            errorMessage="Domain name is required"
            required
            isDomainName
            isDomainNameErrorMessage="Invalid domain name"
            register={register}
            errors={errors}
            defaultValue={data?.pendingDomains?.rootDomainName}
            inputClassName={hoverClassNames}
          />
          {showSubdomainName && (
            <>
              <TextFieldForm
                containerClassName="!mb-0"
                label="Subdomain name"
                name="subdomainName"
                placeholder="Enter subdomain name"
                errorMessage="Subdomain name is required"
                required
                isSubdomainName
                isSubdomainNameErrorMessage="Invalid subdomain name"
                register={register}
                errors={errors}
                defaultValue={data?.pendingDomains?.subdomainName}
                inputClassName={hoverClassNames}
              />
              {subdomainName && rootDomainName && (
                <div className="bg-grey-900 rounded-md px-3 py-2">
                  <div className="text-sm space-x-1">
                    <span>Preview:</span>
                    <span className="font-medium">
                      {subdomainName}.{rootDomainName}
                    </span>
                  </div>
                  <p className="text-grey-500 text-xs">
                    This URL will be connected to your Practice
                  </p>
                </div>
              )}
            </>
          )}
        </form>
      </PublicProfileSidebarSection>
    </>
  );
};

export default ConnectDomainStep1Page;
