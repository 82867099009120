import React, { ReactNode } from "react";

import Toggle from "@components/Form/Toggle";

interface ToggleItemProps {
  label: string | ReactNode;
  description: string;
  value: boolean | undefined;
  onChange: (value: boolean) => void;
}

const ToggleItem: React.FC<ToggleItemProps> = ({
  label,
  description,
  value,
  onChange,
}) => (
  <div className="flex gap-3 p-3 items-start bg-white border border-grey-900 rounded-lg">
    <div className="flex-1 flex flex-col gap-0.5">
      <p className="flex gap-1.5 items-center text-sm leading-tight">{label}</p>
      <p className="text-xs text-grey-500">{description}</p>
    </div>
    <Toggle
      onChange={onChange}
      value={value ?? false}
      disabled={value === undefined}
      size="small"
    />
  </div>
);

export default ToggleItem;
