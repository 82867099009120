import { ReactNode } from "react";

import ChevronLeftIcon from "@components/Icons/ChevronLeftIcon";

interface PublicProfileSidebarItemProps {
  icon: ReactNode;
  title: string;
  onClick: () => void;
  description?: string;
}

const PublicProfileSidebarItem: React.FC<PublicProfileSidebarItemProps> = ({
  icon,
  title,
  description,
  onClick,
}) => (
  <div
    className="w-full flex items-center gap-3 p-3 bg-white hover:cursor-pointer border border-grey-900 rounded-lg truncate"
    onClick={onClick}
  >
    <span className="shrink-0">{icon}</span>
    <span className="flex flex-col items-start text-left truncate">
      <span className="w-full text-sm text-black-ink truncate">{title}</span>
      <span className="w-full text-xs text-grey-500 truncate">
        {description}
      </span>
    </span>
    <ChevronLeftIcon className="ml-auto rotate-180 shrink-0 text-grey-800" />
  </div>
);

export default PublicProfileSidebarItem;
