import { FC } from "react";
import classNames from "classnames";

import { usePublicProfile } from "@contexts/publicProfile";
import {
  PageResourceType,
  PublicProfileUserData,
} from "@contexts/publicProfile/types";
import { displayNameFromContact } from "@lib/contacts";
import { AccountType } from "@lib/data/schemas/account";
import { getCoachBaseUrl } from "@lib/utils";

import { Button } from "@components/Button";
import ClientAvatar from "@components/Client/ClientAvatar";

import { PublicProfileSidebarPage } from "./PublicProfileSidebar/Pages";

interface MembersSectionProps {
  editMode?: boolean;
  user: PublicProfileUserData;
  members: AccountType[];
  setSelectedPage?: React.Dispatch<
    React.SetStateAction<PublicProfileSidebarPage>
  >;
}

const MembersSection: FC<MembersSectionProps> = ({
  editMode,
  members,
  user,
  setSelectedPage,
}) => {
  const { setPageResource } = usePublicProfile();
  const shownMembers = members?.filter((member) => !member.secret);
  if (shownMembers.length === 0) return null;

  const setToMember = (member: AccountType) => {
    setPageResource({ ...member, isMemberResource: true } as PageResourceType);
    setSelectedPage && setSelectedPage(PublicProfileSidebarPage.root);
  };

  const renderMemberCard = (member: AccountType) => (
    <div onClick={editMode ? () => setToMember(member) : undefined}>
      <a
        href={
          editMode
            ? undefined
            : `${getCoachBaseUrl(user)}/${member.slug ?? member.id}`
        }
        target="_blank"
        className={classNames(
          "p-4 flex items-center gap-4 bg-foreground/7 rounded-2xl h-full",
          editMode
            ? "lg:items-start lg:flex-col lg:justify-between"
            : "sm:items-start sm:flex-col sm:justify-between"
        )}
      >
        {member.avatarURL ? (
          <img
            src={member.avatarURL}
            className="h-[56px] w-[56px] object-cover rounded-md"
          />
        ) : (
          <div className="flex items-start">
            <ClientAvatar
              client={member}
              className="!mx-0 !bg-foreground/30 !text-foreground"
              size="medium"
            />
          </div>
        )}
        <div className="w-full">
          <h1 className="font-medium truncate">
            {displayNameFromContact(member)}
          </h1>
          <h2 className="text-sm text-foreground/50 truncate">
            {member.title}
          </h2>
        </div>
        <div
          className={classNames("hidden", editMode ? "lg:block" : "sm:block")}
        >
          <Button
            className="!px-3 !py-1.5 !rounded-lg text-sm"
            variant="ghost-theme"
          >
            Learn more
          </Button>
        </div>
      </a>
    </div>
  );

  return (
    <div className="mb-16" id="members-section">
      <h1 className="text-2xl font-medium mb-4">
        {user?.sectionTitles?.members || "Meet the team"}
      </h1>
      <div
        className={classNames(
          "grid gap-4",
          editMode
            ? "lg:grid-cols-2 xl:grid-cols-3"
            : "md:grid-cols-2 lg:grid-cols-3"
        )}
      >
        {shownMembers?.map(renderMemberCard)}
      </div>
    </div>
  );
};

export default MembersSection;
