import React, { FC } from "react";
import classNames from "classnames";

import { PublicProfileUserData } from "@contexts/publicProfile/types";
import { displayNameFromContact } from "@lib/contacts";

import ClientBio from "@components/Client/ClientBio";
import ClientHeaderButtons from "@components/Client/ClientHeaderButtons";
import ClientCheckIcon from "@components/Icons/ClientCheckIcon";
import PictureIcon from "@components/Icons/PictureIcon";

import { innerClassnames } from "./PublicProfilePage";
import SocialLinks, { renderCoachLocation } from "./SocialLinks";

interface CompanyAboutSectionProps {
  user: PublicProfileUserData;
  editMode?: boolean;
}

const CompanyAboutSection: FC<CompanyAboutSectionProps> = ({
  editMode,
  user,
}) => {
  const {
    companyName,
    title,
    bio,
    coachLocation,
    socialLinks,
    website,
    showLoginButton,
    slug,
    companyDetails = {},
  } = user;

  const { showHero, heroImage, heroTitle, companyLogoMark } = companyDetails;

  const renderLoginButton = showLoginButton && (
    <div className="bg-background rounded-md absolute top-4 right-4">
      <div className="bg-foreground/10 text-foreground rounded-md">
        <ClientHeaderButtons
          headerButtons={[
            {
              icon: <ClientCheckIcon />,
              text: "Client login",
              isIconButton: false,
              href: `/me/${slug}/login`,
              heapEventName: "client_login_button_clicked",
              variant: "ghost-theme",
            },
          ]}
        />
      </div>
    </div>
  );

  const renderSocialLinks = (showOnlyIcons: boolean) => (
    <SocialLinks
      links={socialLinks}
      website={website}
      showOnlyIcons={showOnlyIcons}
    />
  );

  const hasName = user?.firstName || user?.lastName;
  const name = hasName ? displayNameFromContact(user) : "";

  return (
    <div id="about-company-section">
      <div className="relative">
        {heroImage && showHero && (
          <>
            <img
              src={heroImage}
              className="h-1/4 lg:h-[490px] w-full object-cover"
            />
            <h1 className="w-full lg:text-5xl text-3xl absolute bottom-6 text-center drop-shadow-[0_0.5px_0.5px_rgba(0,0,0,0.8)] text-white">
              {heroTitle}
            </h1>
          </>
        )}
        {!heroImage && showHero && editMode && (
          <div className="bg-foreground/10 h-[490px] flex justify-center items-center">
            <div className="flex flex-col items-center gap-2 text-foreground/40">
              <PictureIcon />
              Image & text will be shown here
            </div>
          </div>
        )}
        {renderLoginButton}
      </div>
      <div className={innerClassnames}>
        <div
          className={classNames(
            "w-full grid-cols-12 py-12 gap-12",
            (!heroImage || !showHero) && "pt-24",
            editMode ? "xl:grid" : "lg:grid"
          )}
        >
          <div
            className={classNames(
              "mb-4 lg:mb-0 gap-8 h-min col-span-5",
              editMode ? "lg:flex" : "md:flex"
            )}
          >
            <div key={companyLogoMark}>
              {companyLogoMark && (
                <div
                  className={classNames(
                    "flex items-center mb-4",
                    editMode ? "xl:mb-0" : "lg:mb-0"
                  )}
                >
                  <img src={companyLogoMark} className="max-w-[100px] mt-2" />
                </div>
              )}
            </div>
            <div
              className={classNames(
                !companyLogoMark && (editMode ? "lg:-ml-8" : "md:-ml-8")
              )}
            >
              <h1 className="text-4xl">{companyName ?? name}</h1>
              <h2 className="text-xl text-foreground/75 mb-4">{title}</h2>
              {renderCoachLocation(coachLocation)}
              {!bio && <div className="mt-1">{renderSocialLinks(false)}</div>}
            </div>
          </div>

          <div
            className={classNames(
              "mt-4 col-span-7",
              editMode ? "xl:mt-0" : "lg:mt-0"
            )}
          >
            <ClientBio
              content={bio}
              maxNumberOfLines={6}
              containerStyles="!pb-0"
            />
            {bio && renderSocialLinks(true)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyAboutSection;
