import { FC } from "react";
import classNames from "classnames";

import { displayNameFromContact } from "@lib/contacts";
import { UserType } from "@lib/data/schemas/user";

import ClientBio from "@components/Client/ClientBio";
import SocialLinks from "@components/PublicProfile/SocialLinks";

interface AboutSectionProps {
  user: Partial<UserType>;
  editMode?: boolean;
  isMemberPage?: boolean;
}

const AboutSection: FC<AboutSectionProps> = ({
  user,
  editMode,
  isMemberPage = false,
}) => {
  const { avatarURL, coachLocation, title, bio, socialLinks, website } = user;
  const coachName = displayNameFromContact(user);

  const imgClassNames = editMode
    ? "xl:mb-0 sm:w-[200px] sm:h-[260px] lg:w-[245px] xl:h-[320px]"
    : "md:mb-0 sm:w-[245px] sm:h-[320px]";

  const image = avatarURL && (
    <div className="flex justify-center">
      <img
        className={classNames(
          "rounded-2xl object-cover max-w-none mt-2 md:-mr-10 lg:-mr-0 w-[124px] h-[160px] mb-7",
          imgClassNames,
          editMode && avatarURL && "lg:ml-10 xl:ml-0"
        )}
        src={avatarURL}
        alt={`${coachName} - ${title}`}
      />
    </div>
  );

  const orgTitle = (
    <>
      <h1 className="text-3xl text-center sm:text-4xl font-medium text-foreground">
        {coachName}
      </h1>
      <h2 className="text-center sm:text-xl pb-8 -mt-1 text-foreground/50">
        {title}
      </h2>
    </>
  );

  return (
    <div
      id="about-you-section"
      className={classNames(
        "pt-8 mb-16",
        avatarURL && (isMemberPage ? "md:pt-10" : "md:pt-20"),
        editMode ? "lg:grid lg:grid-cols-4" : "md:grid md:grid-cols-4"
      )}
    >
      {image}
      <div
        className={classNames(
          "w-full col-span-3 flex flex-col items-center md:items-start",
          avatarURL && "md:pl-20"
        )}
      >
        {orgTitle}
        {bio && (
          <ClientBio content={bio} containerStyles="px-0 sm:px-16 md:px-0" />
        )}
        <SocialLinks
          coachLocation={coachLocation}
          links={socialLinks}
          website={website}
        />
      </div>
    </div>
  );
};

export default AboutSection;
