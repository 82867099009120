import { FC } from "react";

import AboutYouPage from "@components/PublicProfile/PublicProfileSidebar/Pages/AboutYouPage";
import AppearanceAndLayoutPage from "@components/PublicProfile/PublicProfileSidebar/Pages/AppearanceAndLayoutPage";
import ConnectDomainStep1Page from "@components/PublicProfile/PublicProfileSidebar/Pages/ConnectDomainStep1Page";
import ConnectDomainStep2Page from "@components/PublicProfile/PublicProfileSidebar/Pages/ConnectDomainStep2Page";
import DomainsAndPresencePage from "@components/PublicProfile/PublicProfileSidebar/Pages/DomainsAndPresencePage";
import EditTestimonialPage from "@components/PublicProfile/PublicProfileSidebar/Pages/EditTestimonialPage";
import PackagesPage from "@components/PublicProfile/PublicProfileSidebar/Pages/PackagesPage";
import RootPage from "@components/PublicProfile/PublicProfileSidebar/Pages/RootPage";
import SchedulersPage from "@components/PublicProfile/PublicProfileSidebar/Pages/SchedulersPage";
import TestimonialsPage from "@components/PublicProfile/PublicProfileSidebar/Pages/TestimonialsPage";

import AboutCompany from "./AboutCompany";
import MembersPage from "./MembersPage";

export enum PublicProfileSidebarPage {
  root = "root",
  appearanceAndLayout = "appearanceAndLayout",
  domainsAndPresence = "domainsAndPresence",
  connectDomainStep1 = "connectDomainStep1",
  connectDomainStep2 = "connectDomainStep2",
  aboutYou = "aboutYou",
  schedulers = "schedulers",
  packages = "packages",
  testimonials = "testimonials",
  editTestimonial = "editTestimonial",
  aboutCompany = "aboutCompany",
  members = "members",
}

export interface PublicProfileSidebarPageProps {
  setSelectedPage: (page: PublicProfileSidebarPage) => void;
  onClickBack?: () => void;
  isMemberPage?: boolean;
}

export const publicProfileSidebarPages: Record<
  PublicProfileSidebarPage,
  {
    component: FC<PublicProfileSidebarPageProps>;
    previousPage?: PublicProfileSidebarPage;
    customHeader?: boolean;
  }
> = {
  root: {
    component: RootPage,
    customHeader: true,
  },
  appearanceAndLayout: {
    component: AppearanceAndLayoutPage,
    previousPage: PublicProfileSidebarPage.root,
  },
  domainsAndPresence: {
    component: DomainsAndPresencePage,
    previousPage: PublicProfileSidebarPage.root,
  },
  connectDomainStep1: {
    component: ConnectDomainStep1Page,
    previousPage: PublicProfileSidebarPage.domainsAndPresence,
    customHeader: true,
  },
  connectDomainStep2: {
    component: ConnectDomainStep2Page,
    previousPage: PublicProfileSidebarPage.connectDomainStep1,
    customHeader: true,
  },
  aboutYou: {
    component: AboutYouPage,
    previousPage: PublicProfileSidebarPage.root,
  },
  aboutCompany: {
    component: AboutCompany,
    previousPage: PublicProfileSidebarPage.root,
  },
  members: {
    component: MembersPage,
    previousPage: PublicProfileSidebarPage.root,
    customHeader: true,
  },
  schedulers: {
    component: SchedulersPage,
    previousPage: PublicProfileSidebarPage.root,
  },
  packages: {
    component: PackagesPage,
    previousPage: PublicProfileSidebarPage.root,
  },
  testimonials: {
    component: TestimonialsPage,
    previousPage: PublicProfileSidebarPage.root,
  },
  editTestimonial: {
    component: EditTestimonialPage,
    previousPage: PublicProfileSidebarPage.testimonials,
    customHeader: true,
  },
};
