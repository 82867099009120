import { FC } from "react";
import { DragDropContextProps } from "react-beautiful-dnd";

import { useProfileData } from "@hooks/useProfileData";
import { SchedulerType } from "@lib/data/schemas/scheduler";

import { AddButton } from "@components/Buttons";
import FormDragAndDrop from "@components/FormDragAndDrop";
import { PublicProfileSidebarPageProps } from "@components/PublicProfile/PublicProfileSidebar/Pages";
import PublicProfileSidebarSection from "@components/PublicProfile/PublicProfileSidebarSection";
import SchedulerPicker from "@components/SchedulerPicker";

import {
  getHiddenItems,
  getItemsAfterDrag,
  getShownItems,
  MemberItemType,
  updateHiddenStatus,
  updateMemberItemsOrder,
  updateMemberItemStatus,
} from "../utils";

import PublicItemCard, { PublicItemType } from "./PublicItemCard";
import SectionTitle from "./SectionTitle";
import SidebarPageEmptyState from "./SidebarPageEmptyState";

const SchedulersPage: FC<PublicProfileSidebarPageProps> = ({
  isMemberPage = false,
}) => {
  const { data, updateData, isNestedMember } = useProfileData(isMemberPage);
  const isMember = isMemberPage || isNestedMember;

  const schedulers = data?.schedulers as PublicItemType[];

  if (schedulers?.length === 0)
    return (
      <SidebarPageEmptyState>
        No schedulers to show. Create schedulers from the appointments tab.
      </SidebarPageEmptyState>
    );

  const shown = getShownItems(
    schedulers,
    isMember,
    data?.memberSchedulers
  ) as PublicItemType[];
  const hiddenSchedulers = getHiddenItems(
    schedulers,
    isMember,
    data?.memberSchedulers
  );

  const onDragEnd: DragDropContextProps["onDragEnd"] = (result) => {
    if (!result.destination) return; // dropped outside the list
    const newItems = getItemsAfterDrag(
      result.source.index,
      result.destination.index,
      shown,
      schedulers
    ) as PublicItemType[];
    if (newItems) {
      if (isMember) {
        const memberItems = updateMemberItemsOrder(
          newItems,
          data?.memberSchedulers || []
        ) as MemberItemType[];
        updateData({ memberSchedulers: memberItems });
      } else {
        updateData({ schedulers: newItems });
      }
    }
  };

  const onUpdateHiddenStatus = (
    scheduler: PublicItemType,
    newStatus: boolean
  ) => {
    const newItems = updateHiddenStatus(
      scheduler,
      newStatus,
      schedulers
    ) as PublicItemType[];
    if (newItems) {
      if (isMember) {
        const memberItems = updateMemberItemStatus(
          scheduler,
          newStatus,
          data?.memberSchedulers || []
        );
        updateData({ memberSchedulers: memberItems });
      } else {
        updateData({ schedulers: newItems });
      }
    }
  };

  return (
    <PublicProfileSidebarSection title="Schedulers">
      {shown.length > 0 && (
        <PublicProfileSidebarSection title="Section title" level={2}>
          <SectionTitle
            type="schedulers"
            placeholder="Book an appointment"
            isMemberPage={isMemberPage}
          />
        </PublicProfileSidebarSection>
      )}
      <div>
        <FormDragAndDrop
          onDragEnd={onDragEnd}
          fields={shown || []}
          itemClassname="w-full mb-2 rounded-lg"
        >
          {(field, index) => {
            return (
              <PublicItemCard
                key={`scheduler-${index}`}
                item={field as PublicItemType}
                onUpdateHiddenStatus={onUpdateHiddenStatus}
                type="scheduler"
              />
            );
          }}
        </FormDragAndDrop>

        {hiddenSchedulers?.length > 0 && (
          <SchedulerPicker
            onSchedulerChange={(scheduler) =>
              onUpdateHiddenStatus(scheduler, false)
            }
            label={null}
            customPlaceholder={
              <AddButton className="py-6 hover:bg-grey-900 bg-grey-950">
                Add a scheduler
              </AddButton>
            }
            schedulers={hiddenSchedulers as SchedulerType[]}
            className="!mt-2 shadow-none"
          />
        )}
      </div>
    </PublicProfileSidebarSection>
  );
};

export default SchedulersPage;
