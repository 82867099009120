import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C7.1716 1 3 4.66858 3 9.5V15V20H5C3 20 3 20.001 3 20.002L3 20.004L3.00002 20.0081L3.00007 20.0166L3.00029 20.0351C3.0005 20.0481 3.00084 20.0624 3.00135 20.0779C3.00238 20.1088 3.00414 20.1449 3.00704 20.1855C3.01279 20.266 3.02332 20.3679 3.04284 20.485C3.08029 20.7098 3.15893 21.04 3.33615 21.3944C3.51728 21.7567 3.82038 22.1798 4.31247 22.5079C4.81709 22.8443 5.40003 23 6 23C7.69762 23 8.6203 21.8468 9 21.3353C9.3797 21.8468 10.3024 23 12 23C13.6976 23 14.6203 21.8468 15 21.3353C15.3797 21.8468 16.3024 23 18 23C18.6 23 19.1829 22.8443 19.6875 22.5079C20.1796 22.1798 20.4827 21.7567 20.6639 21.3944C20.8411 21.04 20.9197 20.7098 20.9572 20.485C20.9767 20.3679 20.9872 20.266 20.993 20.1855C20.9959 20.1449 20.9976 20.1088 20.9986 20.0779C20.999 20.0684 20.9992 20.0593 20.9994 20.0507C20.9995 20.0453 20.9996 20.0401 20.9997 20.0351L20.9999 20.0166L21 20.0081L21 20.004L21 20.002C21 20.001 21 20 19 20H21V9.5C21 4.66858 16.8284 1 12 1ZM19 20C19 20 19 21 18 21C17.25 21 16.875 20.5 16.5 20C16.125 19.5 15.75 19 15 19C14.25 19 13.875 19.5 13.5 20C13.125 20.5 12.75 21 12 21C11.25 21 10.875 20.5 10.5 20C10.125 19.5 9.75 19 9 19C8.25 19 7.875 19.5 7.5 20C7.125 20.5 6.75 21 6 21C5 21 5 20 5 20L5.00003 17L5 9.5C5 5.91015 8.13401 3 12 3C15.866 3 19 5.91015 19 9.5L19 20ZM7 10C7 11.1046 7.89543 12 9 12C10.1046 12 11 11.1046 11 10C11 8.89543 10.1046 8 9 8C7.89543 8 7 8.89543 7 10ZM13 10C13 11.1046 13.8954 12 15 12C16.1046 12 17 11.1046 17 10C17 8.89543 16.1046 8 15 8C13.8954 8 13 8.89543 13 10Z"
      />
    </svg>
  );
};

export default Icon;
