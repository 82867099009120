import { FC, ReactNode } from "react";
import Image from "next/image";
import Link from "next/link";

import { Button } from "@components/Button";
import ArrowIcon from "@components/Icons/ArrowIcon";

interface PublicProfileSidebarHeaderProps {
  children?: ReactNode;
  onClickBack?: () => void;
  buttonTitle?: string;
}

export const PublicProfileSidebarHeader: FC<
  PublicProfileSidebarHeaderProps
> = ({ children, onClickBack, buttonTitle = "Back" }) => (
  <div className="flex items-center justify-between gap-4">
    {onClickBack ? (
      <Button
        variant="outline"
        smaller
        onClick={onClickBack}
        icon={<ArrowIcon />}
      >
        {buttonTitle}
      </Button>
    ) : (
      <Link href="/">
        <Image
          src="/favicon-310.png"
          width={24}
          height={24}
          alt="Practice logo"
        />
      </Link>
    )}
    {children}
  </div>
);
