import { FC, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import CollapsedHeader from "@components/DisplayDetails/CollapsedHeader";

interface ClientBioProps {
  content?: string;
  containerStyles?: string;
  maxNumberOfLines?: number;
}

const ClientBio: FC<ClientBioProps> = ({
  content,
  containerStyles,
  maxNumberOfLines = 10,
}) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [maxHeight, setMaxHeight] = useState<number>(0);
  const [showCollapseButton, setShowCollapseButton] = useState<boolean>(true);
  const targetRef = useRef<HTMLParagraphElement | null>(null);

  useEffect(() => {
    if (!targetRef.current) {
      return;
    }

    const lineHeight = (element: HTMLParagraphElement): number => {
      const lineHeight = window
        .getComputedStyle(element, null)
        .getPropertyValue("line-height");

      return parseInt(lineHeight);
    };

    const numberOfLines = (element: HTMLParagraphElement): number => {
      // Using the scrollHeight property here, which includes the content not visible
      // on the screen due to overflow.
      return element.scrollHeight / lineHeight(element);
    };

    const lines = numberOfLines(targetRef.current);
    const height = lineHeight(targetRef.current);

    setMaxHeight(height * maxNumberOfLines);
    setShowCollapseButton(lines > maxNumberOfLines);
  }, [targetRef, content]); // Observing content to show/hide the collapse button when the user updates the bio

  if (!content) return null;
  return (
    <div
      className={classNames("pb-4 w-full", containerStyles && containerStyles)}
    >
      <div className="group relative">
        <p
          ref={targetRef}
          className={classNames(
            "max-w-2xl whitespace-pre-wrap text-sm sm:text-base text-foreground/75 mb-2",
            isCollapsed ? "overflow-hidden" : "overflow-none"
          )}
          style={isCollapsed ? { maxHeight: maxHeight + "px" } : {}}
        >
          {content}
        </p>
        {isCollapsed && showCollapseButton && (
          <div className="absolute bottom-0 left-0 right-0 flex max-w-2xl h-5 bg-gradient-to-t from-background"></div>
        )}
      </div>
      {showCollapseButton && (
        <div className="flex max-w-2xl w-full">
          <CollapsedHeader
            collapsed={isCollapsed}
            toggleCollapsed={setIsCollapsed}
            leftElement={<>Show {isCollapsed ? "more" : "less"}</>}
            className="text-foreground/50 hover:text-foreground"
          />
        </div>
      )}
    </div>
  );
};

export default ClientBio;
