import { omit, sortBy } from "lodash";

import {
  PageResourceType,
  PublicDataType,
} from "@contexts/publicProfile/types";
import { accountProfileFields } from "@lib/accounts";
import { AccountType } from "@lib/data/schemas/account";

import { PublicItemType } from "./Pages/PublicItemCard";

export type SectionPublicItemType = PublicItemType | AccountType;

const checkStatus = (item: SectionPublicItemType) => {
  if (!("status" in item)) return true;
  return item.status !== "inactive" && item.status !== "deleted";
};

export type MemberItemType = {
  id: string;
  publicOrder?: number;
};

export const getShownItems = (
  items: SectionPublicItemType[] | AccountType[],
  isMemberPage?: boolean,
  memberItems?: MemberItemType[]
) => {
  if (!isMemberPage)
    return sortBy(
      items?.filter((s) => "secret" in s && !s.secret && checkStatus(s)),
      "publicOrder",
      "asc"
    );

  const shown = items
    ?.map((item) => {
      if (!checkStatus(item)) return false;
      const memberItem = memberItems?.find((i) => i.id === item.id);
      if (!memberItem) return false;
      return { ...item, publicOrder: memberItem.publicOrder };
    })
    .filter(Boolean);

  return sortBy(shown, "publicOrder", "asc");
};

export const getHiddenItems = (
  items: SectionPublicItemType[],
  isMemberPage?: boolean,
  memberItems?: MemberItemType[]
) => {
  if (!isMemberPage)
    return items?.filter(
      (s) => (s.secret || !("secret" in s)) && checkStatus(s)
    );

  return items
    ?.map((item) => {
      if (!checkStatus(item)) return false;
      const memberItem = memberItems?.find((i) => i.id === item.id);
      if (memberItem) return false;
      return item;
    })
    .filter(Boolean);
};

export const getItemsAfterDrag = (
  source: number,
  destination: number,
  shown: SectionPublicItemType[],
  items: SectionPublicItemType[]
) => {
  // remove dragged item
  const removed = shown?.splice(source, 1);
  // insert dragged item at new position
  shown?.splice(destination, 0, removed![0]);

  // update publicOrder
  const withPublicOrder = shown?.map((s, i) => ({ ...s, publicOrder: i }));
  const newItems = items?.map((s) => {
    const newItem = withPublicOrder?.find((item) => item.id === s.id);
    return newItem ? newItem : s;
  });

  return newItems;
};

export const updateHiddenStatus = (
  item: SectionPublicItemType,
  newStatus: boolean,
  items: SectionPublicItemType[]
) => {
  const newItems = items?.map((i) => {
    if (i.id === item.id) return { ...i, secret: newStatus };
    return i;
  });
  return newItems;
};

export const updateMemberItemStatus = (
  item: SectionPublicItemType,
  hideItem: boolean,
  items: MemberItemType[]
) => {
  if (hideItem) return items?.filter((i) => i.id !== item.id);
  return [...(items ?? []), { id: item.id }];
};

export const updateMemberItemsOrder = (
  items: SectionPublicItemType[],
  memberItems: MemberItemType[]
) =>
  items
    .map((item) => {
      const found = memberItems?.find((i) => i.id === item.id);
      if (!found) return false;
      return { id: item.id, publicOrder: item.publicOrder };
    })
    .filter(Boolean);

const getItemsWithAccount = (
  memberResource: PageResourceType,
  items?: PublicItemType[]
) =>
  items?.filter(
    (s) =>
      s.accounts?.find(
        (a) =>
          a.id === memberResource?.id ||
          s.accounts?.some((a) => a.id === memberResource?.id)
      )
  ) || [];

type MemberResourceReturnType = PublicDataType & {
  schedulers: PublicItemType[];
  packages: PublicItemType[];
  orgSlug: string;
  isMemberResource: boolean;
};

export const getMemberResourceData = (
  memberResource: PageResourceType,
  orgData: PublicDataType
): MemberResourceReturnType => {
  const memberData = orgData?.members?.find((m) => m.id === memberResource?.id);
  const schedulers = getItemsWithAccount(memberResource, orgData?.schedulers);
  const packages = getItemsWithAccount(memberResource, orgData?.packages);
  const member = {
    ...memberData,
    ...omit(orgData, accountProfileFields),
    featureNames: { ...orgData.featureNames, companyProfile: false },
  };

  const data = {
    ...member,
    schedulers: schedulers,
    packages,
    orgSlug: orgData.slug ?? "",
    isMemberResource: true,
  } as MemberResourceReturnType;

  return data;
};
