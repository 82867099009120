import { FC } from "react";

import { UserType } from "@lib/data/schemas/user";

import PublicBookingSection, {
  ItemType,
} from "@components/PublicProfile/PublicBookingSection";

import { SectionPublicItemType } from "./PublicProfileSidebar/utils";

interface BookingItemsProps {
  schedulers: SectionPublicItemType[];
  packages: SectionPublicItemType[];
  slug?: string;
  editMode?: boolean;
  sectionTitles?: UserType["sectionTitles"];
}

const BookingItems: FC<BookingItemsProps> = ({
  schedulers,
  packages,
  slug,
  editMode,
  sectionTitles,
}) => {
  return (
    <div className="flex flex-col gap-16">
      {!!schedulers.length && (
        <PublicBookingSection
          items={schedulers as ItemType[]}
          title={sectionTitles?.schedulers || "Book an appointment"}
          userSlug={slug}
          type="scheduler"
          editMode={editMode}
        />
      )}
      {!!packages.length && (
        <PublicBookingSection
          items={packages as ItemType[]}
          title={sectionTitles?.packages || "Start a package"}
          userSlug={slug}
          type="package"
          editMode={editMode}
        />
      )}
    </div>
  );
};

export default BookingItems;
