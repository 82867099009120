import React, { FC } from "react";
import classNames from "classnames";

import InstagramIcon from "@components/Icons/InstagramIcon";
import LinkedinIcon from "@components/Icons/LinkedinIcon";
import LocationIcon from "@components/Icons/LocationIcon";
import SocialWebIcon from "@components/Icons/SocialWebIcon";
import TwitterIcon from "@components/Icons/TwitterIcon";
import { SocialLinksProps } from "@components/PublicProfile/types";

export const renderCoachLocation = (location?: string) => {
  if (!location) return null;
  return (
    <div className="flex items-center mb-1 text-foreground/75">
      <LocationIcon className="mr-2" />
      <div>{location}</div>
    </div>
  );
};

const SocialLinks: FC<SocialLinksProps> = ({
  links,
  website,
  coachLocation,
  showOnlyIcons = false,
  className,
}) => {
  const needsHttp = website && website.slice(0, 4) !== "http";

  const socialLinks = [
    {
      social: "https://instagram.com/",
      handle: links?.instagram,
      icon: InstagramIcon,
    },
    {
      social: "https://twitter.com/",
      handle: links?.twitter,
      icon: TwitterIcon,
    },
    {
      social: "https://linkedin.com/in/",
      handle: links?.linkedin || links?.LinkedIn,
      icon: LinkedinIcon,
    },
  ];

  const getHandle = (handleOrURL: string) => {
    const parts = handleOrURL.split("/");

    return parts[parts.length - 1];
  };

  return (
    <div
      className={classNames("pt-2", showOnlyIcons && "flex gap-2", className)}
    >
      {renderCoachLocation(coachLocation)}
      {website && (
        <div className="flex items-center mb-1 text-accent/75 hover:text-accent">
          <a
            href={needsHttp ? "//" + website : website}
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex gap-2">
              <SocialWebIcon className="w-6 h-6" />
              {!showOnlyIcons && website}
            </div>
          </a>
        </div>
      )}
      {socialLinks.map((site, index) => {
        if (site.handle) {
          return (
            <div key={index}>
              <a
                href={site?.social + getHandle(site?.handle)}
                target="_blank"
                rel="noreferrer"
                className={classNames(site.social === "linkedin" && "-mb-1")}
              >
                <div className="flex items-center mb-1 text-accent/80 hover:text-accent gap-2">
                  <site.icon className="w-6 h-6" />
                  {!showOnlyIcons && site.handle}
                </div>
              </a>
            </div>
          );
        }
      })}
    </div>
  );
};

export default SocialLinks;
