import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const AutoIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    >
      <g clipPath="url(#clip0_6138_2766)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 0C11.4477 0 11 0.447715 11 1V3C11 3.55228 11.4477 4 12 4C12.5522 4 13 3.55228 13 3V1C13 0.447715 12.5522 0 12 0ZM3.51468 3.51471C3.9052 3.12419 4.53837 3.12419 4.92889 3.51471L6.34311 4.92892C6.73363 5.31945 6.73363 5.95261 6.34311 6.34314C5.95258 6.73366 5.31942 6.73366 4.92889 6.34314L3.51468 4.92892C3.12416 4.5384 3.12416 3.90523 3.51468 3.51471ZM4.92889 17.6568C5.31942 17.2663 5.95258 17.2663 6.34311 17.6568C6.73363 18.0474 6.73363 18.6805 6.34311 19.0711L4.92889 20.4853C4.53837 20.8758 3.9052 20.8758 3.51468 20.4853C3.12416 20.0947 3.12416 19.4616 3.51468 19.0711L4.92889 17.6568ZM10.9999 21C10.9999 20.4477 11.4476 20 11.9999 20C12.5522 20 12.9999 20.4477 12.9999 21V23C12.9999 23.5523 12.5522 24 11.9999 24C11.4476 24 10.9999 23.5523 10.9999 23V21ZM0.999969 11C0.447685 11 -3.05176e-05 11.4477 -3.05176e-05 12C-3.05176e-05 12.5523 0.447685 13 0.999969 13H2.99997C3.55225 13 3.99997 12.5523 3.99997 12C3.99997 11.4477 3.55225 11 2.99997 11H0.999969ZM11.9999 7.99998C9.79081 7.99998 7.99995 9.79084 7.99995 12C7.99995 14.2091 9.79081 16 11.9999 16V7.99998ZM11.9999 18C8.68624 18 5.99995 15.3137 5.99995 12C5.99995 8.68627 8.68624 5.99998 11.9999 5.99998C15.3137 5.99998 17.9999 8.68627 17.9999 12C17.9999 15.3137 15.3137 18 11.9999 18Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_6138_2766">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AutoIcon;
