import { useCallback } from "react";
import { omit } from "lodash";
import { useDocument } from "swr-firebase";

import { UserType } from "@lib/data/schemas/user";

const OMITTED_FIELDS = ["firstName", "lastName", "avatarURL"];

type UseOrganizationType = (
  oid: string | undefined,
  includeAllFields?: boolean
) => {
  user: UserType;
  update: (data: Partial<UserType>) => Promise<void> | null;
  loading: boolean;
  isValidating: boolean;
};

const useOrganization: UseOrganizationType = (
  oid: string | undefined,
  includeAllFields = false
) => {
  const {
    data: org,
    update: updateOrg,
    isValidating: isValidatingOrg,
  } = useDocument(oid ? `users/${oid}` : null, { listen: true });

  const update = useCallback(
    async (data: Partial<UserType>) => {
      includeAllFields
        ? updateOrg(data)
        : updateOrg(omit(data, OMITTED_FIELDS));
    },
    [updateOrg, includeAllFields]
  );

  return {
    user: org as UserType,
    update,
    loading: !!oid && !org,
    isValidating: isValidatingOrg,
  };
};

export default useOrganization;
