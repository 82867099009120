import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { collection, getDocs, query, where } from "firebase/firestore";

import { useProfileData } from "@hooks/useProfileData";
import { compatDB as db } from "@lib/firebase-config";
import { getCurrentURIFromServer } from "@lib/utils";

import TextFieldForm from "@components/Form/TextFieldForm";

interface UserSlugProps {
  isMemberPage?: boolean;
}

const UserSlug: FC<UserSlugProps> = ({ isMemberPage = false }) => {
  const { data, updateData } = useProfileData(isMemberPage);

  const {
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const domains = data?.domains;

  const slugValidator = async (slug: string) => {
    const slugUser = await getDocs(
      query(collection(db, "users"), where("slug", "==", slug))
    );
    if (!slugUser.empty && slugUser.docs[0].id !== data?.id)
      return Promise.resolve("This username is already being used");
    if (!slug.match("^[a-z0-9.-]*$"))
      return Promise.resolve(
        "Username must contain lowercase letters or numbers"
      );
    if (!slug) return Promise.resolve("Username is required");
  };

  const onChange = async (e: any) => {
    const newSlug = e.target.value.toLowerCase();
    const error = await slugValidator(newSlug);
    if (error) {
      setError("slug", {
        message: error,
      });
    } else {
      clearErrors("slug");
      updateData({ slug: newSlug });
    }
  };

  const practiceLink = `${getCurrentURIFromServer()}/me/${
    isMemberPage ? `${data?.orgSlug}/` : ""
  }`;

  return (
    <div>
      <div className="bg-grey-950 text-sm px-3 py-2.5 text-grey-500 border rounded-t-lg truncate">
        {domains?.useCustomDomain && isMemberPage
          ? `${domains.domainName}/`
          : practiceLink}
      </div>
      <TextFieldForm
        name="slug"
        containerClassName="!mb-0 -mt-1"
        inputClassName="rounded-t-none rounded-b-lg !mt-0 hover:bg-white"
        placeholder="username"
        defaultValue={data?.slug}
        onInputChange={onChange}
        errors={errors}
        required
      />
    </div>
  );
};

export default UserSlug;
