import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 11.5001C1 11.2239 1.22386 11.0001 1.5 11L13.4142 11.0001L11.9394 9.52517C11.7441 9.32991 11.7441 9.01332 11.9394 8.81806L12.6465 8.11095C12.8417 7.91569 13.1583 7.91569 13.3536 8.11095L16.8891 11.6465C16.9623 11.7197 17.0081 11.81 17.0264 11.9045C17.0569 12.0621 17.0112 12.2316 16.8891 12.3536L13.3536 15.8891C13.1583 16.0844 12.8417 16.0844 12.6465 15.8891L11.9394 15.182C11.7441 14.9868 11.7441 14.6702 11.9394 14.4749L13.4142 13.0001H1.5C1.22386 13 1 12.7762 1 12.5001L1 11.5001ZM21 14C22.1046 14 23 13.1046 23 12C23 10.8955 22.1046 10 21 10C19.8954 10 19 10.8955 19 12C19 13.1046 19.8954 14 21 14Z"
      />
    </svg>
  );
};

export default Icon;
