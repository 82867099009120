import { FC, useEffect, useState } from "react";

import { usePublicProfile } from "@contexts/publicProfile";
import analytics from "@lib/analytics";

import { Button } from "@components/Button";
import InfoIcon from "@components/Icons/InfoIcon";
import LifeHelpIcon from "@components/Icons/LifeHelpIcon";
import { AboutModal } from "@components/Modals";
import CopyButton from "@components/PublicProfile/CopyButton";
import {
  PublicProfileSidebarPage,
  PublicProfileSidebarPageProps,
} from "@components/PublicProfile/PublicProfileSidebar/Pages";
import { PublicProfileSidebarHeader } from "@components/PublicProfile/PublicProfileSidebarHeader";
import PublicProfileSidebarSection from "@components/PublicProfile/PublicProfileSidebarSection";
import {
  Tooltip,
  TooltipSize,
  TooltipVariant,
} from "@components/Tooltips/Tooltip";

const ConnectDomainStep2Page: FC<PublicProfileSidebarPageProps> = ({
  setSelectedPage,
  onClickBack,
}) => {
  const { data, updateData } = usePublicProfile();
  const subdomainName = data?.pendingDomains?.subdomainName;
  const isSubdomain = !!subdomainName;
  const [type, name, value] = isSubdomain
    ? ["CNAME", subdomainName, process.env.SSLPROXY_CNAME || ""]
    : ["A", "@", process.env.SSLPROXY_IP || ""];

  const [showAboutModal, setShowAboutModal] = useState(false);

  useEffect(() => {
    analytics.track({
      event: "coach_finishes_domain_connect",
      properties: { isSubdomain },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PublicProfileSidebarHeader onClickBack={onClickBack}>
        <Button
          variant="primary"
          smaller
          onClick={() => {
            updateData({
              domains: data?.pendingDomains,
              pendingDomains: null,
            });
            setSelectedPage(PublicProfileSidebarPage.domainsAndPresence);
          }}
        >
          Save changes
        </Button>
      </PublicProfileSidebarHeader>
      <PublicProfileSidebarSection title="Connect your domain (2/2)">
        <div className="flex flex-col gap-6">
          <p className="text-grey-500">
            Almost done! Complete these three steps:
          </p>

          <div className="flex flex-col gap-2">
            <p>1. Sign in to your domain provider</p>
            <p>2. Go to the DNS settings</p>
            <p>3. Add the following nameservers</p>
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex-1 flex justify-between gap-2 px-3 py-2 text-sm border border-dashed border-grey-800 rounded-lg">
              <div className="flex-1 flex flex-col justify-between gap-1">
                <span className="text-grey-500">Type</span>
                <span>{type}</span>
              </div>

              <div className="flex-1 flex flex-col justify-between gap-1 truncate">
                <span className="text-grey-500">Name</span>
                <div className="flex gap-1 items-center">
                  <span className="truncate">{name}</span>
                  <CopyButton
                    className="text-action-500"
                    iconClassName="w-4 h-4"
                    value={name}
                  />
                </div>
              </div>

              <div className="flex-1 flex flex-col justify-between gap-1">
                <div className="flex gap-1 items-center">
                  <span className="text-grey-500">Value</span>
                  <Tooltip
                    trigger={
                      <InfoIcon className="w-4 h-4 text-grey-500 hover:text-black-ink" />
                    }
                    size={TooltipSize.small}
                    variant={TooltipVariant.black}
                  >
                    Some registrars may require a period at the end
                  </Tooltip>
                </div>
                <div className="flex gap-1 items-center">
                  <span>{value}</span>
                  <CopyButton
                    className="text-action-500"
                    iconClassName="w-4 h-4"
                    value={value}
                  />
                </div>
              </div>
            </div>
            <div className="bg-blue-950 text-blue-300 rounded-md flex items-center gap-2 px-3 py-2 text-sm">
              <LifeHelpIcon />
              <p>
                Having trouble?&nbsp;
                <span
                  className="underline cursor-pointer"
                  onClick={() => setShowAboutModal(true)}
                >
                  We are here to help
                </span>
              </p>
            </div>
            <AboutModal
              categoryName="profile"
              show={showAboutModal}
              toggleShow={setShowAboutModal}
            />
          </div>
        </div>
      </PublicProfileSidebarSection>
    </>
  );
};

export default ConnectDomainStep2Page;
