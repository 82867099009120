import { FC, useRef, useState } from "react";
import classNames from "classnames";

import { displayNameFromContact } from "@lib/contacts";
import { AccountType } from "@lib/data/schemas/account";
import { PackageType } from "@lib/data/schemas/packages";
import { SchedulerType } from "@lib/data/schemas/scheduler";
import { handleOutsideClick } from "@lib/utils/handle-outside-click";

import ClientAvatar from "@components/Client/ClientAvatar";
import { ConditionalTeamsRenderer } from "@components/ConditionalTeamsRenderer";
import IconWithDetails from "@components/DisplayDetails/IconWithDetails";
import GhostyIcon from "@components/Icons/GhostyIcon";
import MoreIcon from "@components/Icons/MoreIcon";
import PencilIcon from "@components/Icons/PencilIcon";
import { getPackageSubtitle } from "@components/Package/utils";
import AccountDetail from "@components/PublicProfile/AccountDetail";
import TypeIllustration from "@components/TypeIllustration";

export type PublicItemType = (SchedulerType & PackageType) & {
  accounts: AccountType[];
};

interface PublicItemCardProps {
  item: PublicItemType;
  onUpdateHiddenStatus: (item: PublicItemType, newStatus: boolean) => void;
  type: "scheduler" | "package" | "member";
  onClickEditMember?: () => void;
}

const PublicItemCard: FC<PublicItemCardProps> = ({
  item,
  onUpdateHiddenStatus,
  type,
  onClickEditMember,
}) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { icon, title, duration, accounts, items: packageItems } = item;
  const menuRef = useRef<any>(null);

  const isMember = type === "member";

  handleOutsideClick(menuRef, () => setShowMenu(false));

  const handleClick = () => {
    onUpdateHiddenStatus(item, true);
    setShowMenu(false);
  };

  const header = (
    <div
      className={classNames(
        !isMember && "w-[210px]",
        "text-black font-normal truncate text-md"
      )}
    >
      {isMember ? displayNameFromContact(item) : title}
    </div>
  );

  const subtitle = isMember ? (
    <h3 className="text-grey-500">{item.title}</h3>
  ) : (
    <div className="flex items-center mt-1 text-grey-500">
      {accounts && (
        <ConditionalTeamsRenderer>
          <AccountDetail
            accountClassNames="text-xs font-medium"
            accounts={item.accounts}
            hideNames
          />
        </ConditionalTeamsRenderer>
      )}

      <span className={classNames(accounts && "pl-2")}>
        {duration && `${duration} minutes`}
        {packageItems && getPackageSubtitle(item)}
      </span>
    </div>
  );

  const menuItemClass =
    "p-2 flex items-center gap-4 hover:bg-grey-950 hover:cursor-default";

  const menu = (
    <div
      ref={menuRef}
      className="absolute top-4 right-0 bg-white rounded-md shadow-lg z-50 w-[200px]  font-medium"
    >
      <div className={menuItemClass} onClick={handleClick}>
        <GhostyIcon className="h-5 w-5 ml-1" />
        Hide {type}
      </div>
      {isMember && (
        <div className={menuItemClass} onClick={onClickEditMember}>
          <PencilIcon className="h-5 w-5 ml-1" />
          Edit member
        </div>
      )}
    </div>
  );

  const rightElement = (
    <div onClick={() => setShowMenu(true)} className="relative">
      {showMenu && menu}
      <MoreIcon className="text-grey-800" />
    </div>
  );

  const renderIcon = isMember ? (
    <ClientAvatar className="!mx-0" size="smaller" client={item} />
  ) : (
    <TypeIllustration slug={icon} />
  );

  return (
    <div className="bg-white border px-4 rounded-lg">
      <IconWithDetails
        icon={renderIcon}
        subtitle={subtitle}
        title={header}
        iconClassNames="flex-none bg-transparent !px-0"
        rightElement={rightElement}
      />
    </div>
  );
};

export default PublicItemCard;
